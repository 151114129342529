export type LocalStorageFollowedZone = {
  zoneId: string;
  alias: string;
  dateFollowed: string;
  orderPosition: number;
};

export enum LocalStorageKeys {
  MapStyle = 'mapStyle',
  FollowedZones = 'followedZones',
  PreferredOrigin = 'prefferedOrigin',
  RebrandingBannerShown = 'rebrandingBannerShown',
}
