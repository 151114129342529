import { Box, Typography } from "@mui/material";

import { PropsWithSx } from "../../../../utils";
import Icon from "../../../Icon";
import { Zone } from "../../types";
import { H3Styled } from "~/components/common/Styled/H3Styled";

interface ZoneStatusProps {
  zone: Zone;
}

const getBackgroundColor = (zone) => {
  switch (zone.status) {
    case "Normal":
      return "#BABCC5";
    default:
      return zone.statusColor;
  }
};

const getIconName = (status) => {
  switch (status) {
    case "Evacuation Order":
    case "Evacuation Warning":
    case "Shelter in Place":
      return "Fire";

    case "Normal":
      return "Check";

    default:
      return "Check";
  }
};

export const ZoneStatus = ({
  zone,
  sx,
  ...rest
}: PropsWithSx<ZoneStatusProps>) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: 1,
        flexDirection: "row",
        backgroundColor: "grey.50",
        ...sx,
      }}
      {...rest}
    >
      <Icon
        size={50}
        borderRadius={8}
        color={"#FFFFFF"}
        backgroundShape={"rectangle"}
        name={getIconName(zone.status)}
        backgroundColor={getBackgroundColor(zone)}
      />

      <Box
        sx={{
          pl: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h1" sx={theme => ({ ...theme.typography.body2 })}>{"Status"}</Typography>
        <H3Styled variant="body2">{zone.status}</H3Styled>
      </Box>
    </Box>
  );
};
