import { Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import {
  trackUserDeniedLocationAccess,
  trackUserGrantedLocationAccess,
} from "~/analytics/fns";
import { PrimaryButton, TextButton } from "~/components/common/Buttons";
import { Modal } from "~/components/Modal/Modal";
import { RequestStatus } from "~/constants";
import { I18n } from "~/i18n";
import { useAppDispatch, useAppSelector } from "~/redux-rtk";
import { AppActions } from "~/redux-rtk/slices/appSlice";
import { LocalStorageKeys, LocationOrigin } from "~/types";

import { OnBoardingAnimation } from "../OnboardingAnimation/OnboardingAnimation";

 
export const OnboardingModal = () => {
  const theme = useTheme();
  const [preferredOrigin, setPrefferedOrigin] = useLocalStorage<LocationOrigin>(
    LocalStorageKeys.PreferredOrigin,
    LocationOrigin.Pending
  );

  const userLocation = useAppSelector(
    (state) => state.user.data.currentLocation
  );
  const requestStatus = useAppSelector(
    (state) => state.user.requests.userPositionThunkStatus
  );
  const visibleOnboardingModal = useAppSelector(state => state.app.onboardingModalIsVisible);
  const dispatch = useAppDispatch();


  const handlePermitClick = () => {
    setPrefferedOrigin(LocationOrigin.Browser);
    trackUserGrantedLocationAccess();
  };

  const handleDenyClick = () => {
    setPrefferedOrigin(LocationOrigin.Geotargetly);
    dispatch(AppActions.setOnboardingModalVisible(false))
    trackUserDeniedLocationAccess();
  };

  useEffect(() => {
    if (preferredOrigin === LocationOrigin.Pending) {
      dispatch(AppActions.setOnboardingModalVisible(true));
    }
  }, [preferredOrigin]);

  useEffect(() => {
    if (requestStatus !== RequestStatus.Fulfilled) {
      return;
    }

    if (preferredOrigin || userLocation.origin === LocationOrigin.Browser) {
      dispatch(AppActions.setOnboardingModalVisible(false))
    } else {
      dispatch(AppActions.setOnboardingModalVisible(true))
    }
  }, [requestStatus, preferredOrigin, userLocation]);

  return (
    <Modal
      isOpen={visibleOnboardingModal}
      CardSx={{
        gap: 2,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        bgcolor: theme.palette.common.white,
        overflow: "auto"
      }}
    >
        <OnBoardingAnimation variant={2} />
        <Typography variant="h1">{I18n.t("onboardingModal.title")}</Typography>
        <Typography variant="body2" sx={{ textAlign: "center", pb: 2 }}>
          {I18n.t("onboardingModal.bodyText", { appName: I18n.t('common.appName') })}
        </Typography>

        <PrimaryButton
          onClick={handlePermitClick}
          label={I18n.t("onboardingModal.buttonAllow")}
          sx={{ width: "100%" }}
          isLoading={requestStatus === RequestStatus.Pending}
        />

        <TextButton
          label={I18n.t("onboardingModal.buttonDeny")}
          onClick={handleDenyClick}
          sx={{ width: "100%" }}
        />
    </Modal>
  );
};


