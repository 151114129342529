export const DemoFirebaseConfig = {
  apiKey: "AIzaSyCGkZrQUCQKn-xHKoCk-9TFbyGARE7AZVY",
  authDomain: "aware-demo-27cdf.firebaseapp.com",
  projectId: "aware-demo-27cdf",
  storageBucket: "aware-demo-27cdf.appspot.com",
  messagingSenderId: "21364576922",
  appId: "1:21364576922:web:af82a101e772a8a12c4523",
  measurementId: "G-EQTQCHQS8Q",
};

export const AlphaFirebaseConfig = {
  apiKey: "AIzaSyBaZ-2zQy0mkkMLHqvLK_yaft0q_wAPf4s",
  authDomain: "aware-e134a.firebaseapp.com",
  projectId: "aware-e134a",
  storageBucket: "aware-e134a.appspot.com",
  messagingSenderId: "1026349733326",
  appId: "1:1026349733326:web:fa8ccca46a66a99a99079f",
  measurementId: "G-JQ3CKGJT6J",
};

export const BetaFirebaseConfig = {
  apiKey: "AIzaSyB-6WKdDpzqTJYDCRMeqbqJ2lf0PfCxr90",
  authDomain: "aware-beta.firebaseapp.com",
  projectId: "aware-beta",
  storageBucket: "aware-beta.appspot.com",
  messagingSenderId: "607890528018",
  appId: "1:607890528018:web:7c02d8d6c358aea19445cb",
  measurementId: "G-4Y4RNEJS74",
};

export const ProdFirebaseConfig = {
  apiKey: "AIzaSyCMuyUQ9cQxIC9TKUsl1io3ZqeXeZfTrxY",
  authDomain: "aware-prod-5c8d5.firebaseapp.com",
  projectId: "aware-prod-5c8d5",
  storageBucket: "aware-prod-5c8d5.appspot.com",
  messagingSenderId: "387061698239",
  appId: "1:387061698239:web:4d29ac8b29356776dfc00a",
  measurementId: "G-7BL6SJJBZ3",
};
