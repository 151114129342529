import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, ButtonProps, Typography, useTheme } from '@mui/material';

import { I18n } from '../../../i18n';
import { PropsWithSx } from '../../../utils';

interface TextButtonProps extends ButtonProps {
  label?: string;
  onClick: () => void;
  isEndIconVisible?: boolean;
  endIcon?: React.ReactElement;
}

export const TextButton = ({
  sx,
  onClick,
  endIcon,
  isEndIconVisible = true,
  label = I18n.t('common.moreDetails'),
  ...restProps
}: PropsWithSx<TextButtonProps>) => {
  const theme = useTheme();
  return (
    <Button
      variant="text"
      onClick={onClick}
      endIcon={
        !isEndIconVisible ? null : endIcon ? (
          endIcon
        ) : (
          <KeyboardArrowRightIcon
            sx={{ mx: 0, position: 'relative', right: 4 }}
            aria-hidden="true"
            role="img"
          />
        )
      }
      size="medium"
      sx={{
        width: 'fit-content',
        borderRadius: '50px',
        color: 'primary.main',
        backgroundColor: 'transparent',
        "&:hover, &:focus": {
          boxShadow: 1,
          transform: "scale(1.01)",
          "& > *": {
            color: theme.palette.primary.dark
          }
        },
        ...sx,
      }}
      {...restProps}
    >
      <Typography variant="body2" color={'primary.main'} sx={{ ml: 0.5 }}>
        {label}
      </Typography>
    </Button>
  );
};
