import { Box } from "@mui/material";

import { ReactComponent as ImageLeft } from '~/assets/images/backgrounds/bg-lines-01.svg';
import { ReactComponent as  ImageRight } from '~/assets/images/backgrounds/bg-lines-02.svg';
import { ReactComponent as CenterImage } from '~/assets/images/rebranding-about-title-01.svg';

const RebrandingHeader = () => {
  return <Box
  sx={{
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 54,
    backgroundColor: "#0B3545",
  }}>
    <Box sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: 54,
      height: 54,
    }}>
      <ImageLeft role="img" aria-hidden="true"/>
    </Box>
    <CenterImage role="img" aria-hidden="true"/>
    <Box sx={{
      position: "absolute",
      top: 0,
      right: 0,
      width: 54,
      height: 54,
    }}>
      <ImageRight role="img" aria-hidden="true"/>
    </Box>
  </Box>
}

export default RebrandingHeader;