import { Box, Slide, SxProps, Theme } from "@mui/material";

import { BackButton } from "../../components/common/Buttons/BackButton";
import { useWindowSize } from "../../hooks";
import { I18n } from "../../i18n";
import { forwardRef } from "react";

interface SlidePanelProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  backButtonText?: string;
  sx?: SxProps<Theme>;
}
export const SlidePanel = forwardRef(({
  children,
  isOpen,
  onClose,
  backButtonText,
  sx = {},
}: SlidePanelProps, ref) => {
  const { windowHeight } = useWindowSize();
  return (
    <Slide ref={ref} in={isOpen} direction="up" timeout={300} mountOnEnter unmountOnExit aria-modal="true" role="modal">
      <Box
        sx={{
          position: "absolute",
          overflow: "auto",
          top: 0,
          left: 0,
          zIndex: 1, // let it live under the map (zIndex: 2)
          display: "flex",
          flexDirection: "column",
          height: windowHeight,
          width: "100%",
          backgroundColor: "white",
          pt: 3,
          px: 2,
          pb: 2,
          '@media screen and (orientation: landscape)': {
            top: 70, //relative to header height
            height: "100%"
          },
          ...sx,
        }}
      >
        {onClose && (
          <BackButton
            label={backButtonText ?? I18n.t("common.back")}
            onClick={onClose}
            sx={{
              mb: 2,
              display: "flex",
              alignSelf: "start",
            }}
          />
        )}

        {children}
      </Box>
    </Slide>
  );
});
