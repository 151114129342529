import styled from "styled-components";

import { Color } from "../../constants";

export const StyledToggle = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 48px;
  height: 23px;
`;

export const StyledToggleInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  &:checked + .slider {
    background-color: ${Color.LeafGreen};
  }
  &:focus + .slider {
    box-shadow: 0 0 0 2.5px #00857c;
  }
  &:checked + .slider:before {
    transform: translateX(26px);
  }
`;

export const StyledToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Color.Grey500};
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4.5px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
interface StyledToggleTextProps {
  checked: boolean;
}

export const StyledToggleText = styled.span<StyledToggleTextProps>`
  position: relative;
  cursor: pointer;
  top: 1px !important;
  color: ${Color.White};
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 11px !important;
  left: ${(props) => (props.checked ? "4px" : "15px")};
  user-select: none;
  transition: 0.2s;
`;
