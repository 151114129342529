import { Divider, Typography } from '@mui/material';

import { I18n } from '../../../i18n';
import { Card } from '../../../layouts/Card';

export const StatusDescription = ({ zone }) => {
  return (
    <Card hasShadow={false} backgroundColor="grey">
      {zone.statusReason && (
        <>
          <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
            {I18n.t('zoneDetails.reason')}
          </Typography>
          <Typography variant="body1">{zone.statusReason}</Typography>
          <Divider sx={{ m: 2 }} variant="middle" />
        </>
      )}
      <Typography variant="body1">{zone.statusDescription}</Typography>
    </Card>
  );
};
