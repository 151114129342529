import { Box, Typography } from "@mui/material";
import { isFunction } from "lodash";
import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useWindowSize } from "hooks";

import { BackButton } from "../../components/common/Buttons";
import { focusableElementsString } from "~/constants/ui";

interface PageProps {
  title?: string;
  subtitle?: string;
  hasBackButton?: boolean;
  onBackButtonPress?: () => void;
  onBackPressNavigateTo?: string;
}

export const Page = ({
  title,
  subtitle,
  children,
  onBackPressNavigateTo,
  hasBackButton,
  onBackButtonPress,
}: PropsWithChildren<PageProps>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { windowHeight } = useWindowSize();
  const page = useRef<HTMLDivElement>(null);

  const handleBackPress = useCallback(() => {
    if (isFunction(onBackButtonPress)) {
      return onBackButtonPress();
    }

    return onBackPressNavigateTo
      ? navigate(onBackPressNavigateTo)
      : navigate(-1);
  }, [onBackButtonPress, onBackPressNavigateTo, navigate]);

  const handleKeydown = useCallback((event) => {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9;
    if (!isTabPressed) {
        return;
    }
    const popup = document.getElementsByClassName('maplibregl-popup')[0] as HTMLElement;
    if (!popup) {
      return;
    }
    const focusableElements = page?.current?.querySelectorAll(focusableElementsString);
    const lastFocusableElement = focusableElements && focusableElements[focusableElements.length - 1];
    const popupCloseButton = popup.querySelector("button");

    if(document.activeElement === lastFocusableElement) {
      // Jump to the popup close button
      popupCloseButton?.focus();
      event.preventDefault();
      return;
    }
    // If the current focus is on the close button
    if (document.activeElement === popupCloseButton) {
      // Focus on the first "focusable" element in the bottom right controls, which continues the normal tab order
      let element = document.querySelector(".maplibregl-ctrl-bottom-right")?.querySelector(focusableElementsString);
      (element as HTMLElement)?.focus();
      event.preventDefault();
      return;
    }
    const focusableBottomRightControls = document.querySelector(".maplibregl-ctrl-bottom-right")?.querySelectorAll(focusableElementsString);
    // If we are focused on the last element in the bottom-right controls, we want to loop focus around to the start of the document
    if (document.activeElement === focusableBottomRightControls[focusableBottomRightControls.length - 1]) {
      (document.querySelector(focusableElementsString) as HTMLElement)?.focus();
      event.preventDefault();
      return;
    }
  }, [page])

  useEffect(() => {
    const focusableElements = page?.current?.querySelectorAll(focusableElementsString);
    (focusableElements[0] as HTMLElement).focus();
  }, [])

  useEffect(() => {
    if (location.pathname.includes('/hazards/') || location.pathname.includes('/zones')){
      document.addEventListener('keydown', handleKeydown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    }
  }, [page])

  return (
    <Box
      sx={{
        height:"100%",
        position: "relative",
        width:"100%",
      }}
      ref={page}
    >
      {hasBackButton && (
        <Box
          sx={{
            top: 0,
            zIndex: 2,
            position: "absolute",
            width: "calc(100% - 7px)", // 7px is the width of the scrollbar - we want it to be visible
          }}
        >
          <Box
            sx={{
              px: 3,
              width: "100%",
              height: "50px",
              background: "white",
            }}
          >
            <BackButton
              onClick={handleBackPress}
              sx={{
                top: "24px",
                display: "flex",
                alignSelf: "start",
                position: "relative",
              }}
            />
          </Box>

          <Box
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%)",
            }}
            sx={{
              width: "calc(100% - 7px)",
              height: "35px",
            }}
          />
        </Box>
      )}

      <Box
        id="scrollableContent"
        sx={{
          px: 3,
          display: "flex",
          overflowY: "auto",
          overflowX: "hidden",
          flexDirection: "column",
          width: ["100vw", "100%"],
          height: "100%",
          pt: hasBackButton ? 11 : 3,
          backgroundColor: "common.white",
        }}
      >
        {title && (
          <Typography
            variant="h1"
            sx={{ mt: 4, mb: subtitle ? 2 : 4, textAlign: "center" }}
          >
            {title}
          </Typography>
        )}

        {subtitle && (
          <Box
            sx={{
              mb: 4,
              width: "75%",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ textAlign: "center", alignSelf: "center" }}
            >
              {subtitle}
            </Typography>
          </Box>
        )}

        {children}

        {hasBackButton && <Box sx={{ pt: 4 }} />}
      </Box>
    </Box>
  );
};
