import React from "react";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "../../components/common/Buttons";
import { I18n } from "../../i18n";
import { Page } from "../../layouts/Page";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Page title={I18n.t("errors.thisPageDoesNoExist")}>
      <PrimaryButton
        sx={{ display: "flex", alignSelf: "center" }}
        onClick={() => navigate("/")}
        label={I18n.t("errors.goBackHome")}
      />
    </Page>
  );
};

export default NotFound;
