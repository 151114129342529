import circle from '@turf/circle';
import { Point } from '@turf/helpers';
import { useMemo } from 'react';

import { useAppSelector } from '~/redux-rtk';
import { LocationOrigin } from '~/types';

type Params = {
  isMapLoaded: boolean;
};

export const useUserLocation = ({ isMapLoaded }: Params) => {
  const userLocation = useAppSelector(state => state.user.data.currentLocation);

  const userAccuracyCircle = useMemo(() => {
    if (userLocation?.origin !== LocationOrigin.Browser || !isMapLoaded) {
      return null;
    }

    const { coordinates, accuracyInMeters } = userLocation;

    const accuracyRadius = accuracyInMeters;
    const accuracyCenter: Point = {
      coordinates: [coordinates[1], coordinates[0]],
      type: 'Point',
    };
    const userAccuracyCircle = circle(accuracyCenter, accuracyRadius, {
      steps: 50,
      units: 'meters',
    });

    return userAccuracyCircle;
  }, [userLocation, isMapLoaded]);

  return {
    userAccuracyCircle,
    userPosition: userLocation.coordinates
      ? {
          latitude: userLocation.coordinates[0],
          longitude: userLocation.coordinates[1],
        }
      : null,
  };
};
