import { HazardTypes } from '../types';
import {
  AnalyticsCategories,
  AnalyticsEventsIds,
  AnalyticsEventsLabels,
} from './constants';
import { AnalyticsCallOptions, logEvent } from 'firebase/analytics';
import { hotjar } from 'react-hotjar';
import { getUserOS } from 'utils';
import { FirebaseApp } from '~/setup/Setup';

const { Analytics } = FirebaseApp;

type LogAnalyticsEventParams = {
  eventName: string;
  eventParams?: Record<string, unknown>;
  options?: AnalyticsCallOptions;
};

export const logAnalyticsEvent = (params: LogAnalyticsEventParams) => {
  logEvent(Analytics, params.eventName, params.eventParams, params.options);
};

// All exported tracking functions need to begin with the word "track"
// fn naming should follow same convention as events: "'track':category:verb:label"

// Location granted / denied
export const trackUserGrantedLocationAccess = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserGrantedLocationAccess);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserGrantedLocationAccess,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserGrantedLocationAccess,
    },
  });
};

export const trackUserDeniedLocationAccess = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserDeniedLocationAccess);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserDeniedLocationAccess,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserDeniedLocationAccess,
    },
  });
};

// Map clicks
const trackUserClickedMapFeature = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedMapFeature);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedMapFeature,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedMapFeature,
    },
  });
};

export const trackUserClickedMapRoadBlock = (roadBlockId: string) => {
  trackUserClickedMapFeature();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedMapRoadBlock);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedMapRoadBlock,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedMapRoadBlock,
      roadBlockId,
    },
  });
};

export const trackUserClickedMapZone = (zoneId: string) => {
  trackUserClickedMapFeature();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedMapZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedMapZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedMapZone,
      zoneId,
    },
  });
};

export const trackUserClickedMapFire = (fireId: string) => {
  trackUserClickedMapFeature();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedMapFire);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedMapFire,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedMapFire,
      fireId,
    },
  });
};

export const trackUserClickedMapEvacuationCenter = (
  evacuationCenterId: string,
) => {
  trackUserClickedMapFeature();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedMapEvacuationCenter);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedMapEvacuationCenter,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedMapEvacuationCenter,
      evacuationCenterId,
    },
  });
};

export const trackUserClickedAnimalShelter = (animalShelterId: string) => {
  trackUserClickedMapFeature();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedMapAnimalShelter);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedMapAnimalShelter,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedMapAnimalShelter,
      animalShelterId,
    },
  });
};

// Follow / unfollow zone
export const trackUserFollowedZone = (zoneId: string) => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserFollowedZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserFollowedZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserFollowedZone,
      zoneId,
    },
  });
};

export const trackUserUnfollowedZone = (zoneId: string) => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserUnfollowedZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserUnfollowedZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserUnfollowedZone,
      zoneId,
    },
  });
};

// Address / zone search
const trackUserSearchedAddressOrZone = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserSearchedAddressOrZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserSearchedAddressOrZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserSearchedAddressOrZone,
    },
  });
};

export const trackUserSearchedAddressWithinZone = (zoneId: string) => {
  trackUserSearchedAddressOrZone();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserSearchedAddressWithinZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserSearchedAddressWithinZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserSearchedAddressWithinZone,
      zoneId,
    },
  });
};

export const trackUserSearchedAddressOutsideZone = () => {
  trackUserSearchedAddressOrZone();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserSearchedAddressOutsideZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserSearchedAddressOutsideZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserSearchedAddressOutsideZone,
    },
  });
};

export const trackUserSearchedZoneById = (zoneId: string) => {
  trackUserSearchedAddressOrZone();
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserSearchedZoneById);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserSearchedZoneById,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserSearchedZoneById,
      zoneId,
    },
  });
};

// Not your zone press
export const trackUserClickedNotYourZone = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedNotYourZone);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedNotYourZone,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedNotYourZone,
    },
  });
};

// Feedback
export const trackUserSubmittedFeedback = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserSubmittedFeedback);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserSubmittedFeedback,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserSubmittedFeedback,
    },
  });
};

// views
export const trackUserViewedZoneDetails = (zoneId: string) => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserViewedZoneDetails);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserViewedZoneDetails,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserViewedZoneDetails,
      zoneId,
    },
  });
};

interface ViewedHazardProps {
  hazardType: HazardTypes;
  hazardId: string;
}

export const trackUserViewedHazardDetails = ({
  hazardType,
  hazardId,
}: ViewedHazardProps) => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserViewedHazardDetails);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserViewedHazardDetails,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserViewedHazardDetails,
      hazardId,
      hazardType,
    },
  });
};

export const trackUserClickedDownloadApp = () => {
  if (hotjar.initialized()) {
    hotjar.event(AnalyticsEventsIds.UserClickedDownloadApp);
  }
  logAnalyticsEvent({
    eventName: AnalyticsEventsIds.UserClickedDownloadApp,
    eventParams: {
      category: AnalyticsCategories.User,
      label: AnalyticsEventsLabels.UserClickedDownloadApp,
      userOperatingSystem: getUserOS(),
    },
  });
};
