import { Box, Typography } from "@mui/material";

import { I18n } from "../../../../i18n";
import { getZoneIdFromZoneName } from "../../../../utils";
import { Zone } from "../../types";

interface ZoneNameProps {
  zone: Zone;
  alias?: string;
}

export const ZoneName = ({ zone, alias }: ZoneNameProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1">{I18n.t("myZones.zone")}</Typography>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {getZoneIdFromZoneName(zone.identifier)}
        </Typography>

        {alias && (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ marginLeft: ".3em" }}
          >
            {" • "}
            {alias}
          </Typography>
        )}
      </Box>

      <Typography variant="body1" sx={{ mt: 0.5 }}>
        {zone.commonlyknownas}
      </Typography>
    </Box>
  );
};
