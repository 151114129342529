// every analytics event should be name as follows:
// "category:verb:label" => "user:searched:address_or_zone" => "user_searched_address_or_zone"

export enum AnalyticsEventsIds {
  // User category
  UserDeniedLocationAccess = "1.0.user_denied_location_access",
  UserGrantedLocationAccess = "1.0.user_granted_location_access",

  UserFollowedZone = "2.0.user_followed_zone",
  UserUnfollowedZone = "2.0.user_unfollowed_zone",

  UserClickedMapFeature = "3.0.user_clicked_map_feature",
  UserClickedMapRoadBlock = "3.1.user_clicked_map_road_block",
  UserClickedMapAnimalShelter = "3.2.user_clicked_map_animal_shelter",
  UserClickedMapZone = "3.3.user_clicked_map_zone",
  UserClickedMapFire = "3.4.user_clicked_map_fire",
  UserClickedMapEvacuationCenter = "3.5.user_clicked_map_evacuation_center",

  UserSearchedAddressOrZone = "4.0.user_searched_address_or_zone",
  UserSearchedAddressWithinZone = "4.1.user_searched_address_within_zone",
  UserSearchedAddressOutsideZone = "4.2.user_searched_address_outside_zone",
  UserSearchedZoneById = "4.3.user_searched_zone_by_id",

  UserClickedNotYourZone = "5.0.user_clicked_not_your_zone",

  UserSubmittedFeedback = "6.0.user_submitted_feedback",

  UserViewedZoneDetails = "7.0.user_viewed_zone_details",

  UserViewedHazardDetails = "8.0.user_viewed_hazard_details",

  UserClickedDownloadApp = "9.0.user_clicked_download_app",
}

export enum AnalyticsEventsLabels {
  // User category
  UserDeniedLocationAccess = "User denied location access",
  UserGrantedLocationAccess = "User granted location access",

  UserFollowedZone = "User followed zone",
  UserUnfollowedZone = "User unfollowed zone",

  UserClickedMapFeature = "Parent - User clicked map feature",
  UserClickedMapRoadBlock = "User clicked map road block",
  UserClickedMapAnimalShelter = "User clicked animal shelter",
  UserClickedMapZone = "User clicked map zone",
  UserClickedMapFire = "User clicked map fire",
  UserClickedMapEvacuationCenter = "User clicked evacuation center",

  UserSearchedAddressOrZone = "Parent - User searched address or zone",
  UserSearchedAddressWithinZone = "User searched address within zone",
  UserSearchedAddressOutsideZone = "User searched address outside zone",
  UserSearchedZoneById = "User searched zone by id",

  UserClickedNotYourZone = "User clicked not your zone",

  UserSubmittedFeedback = "User submitted feedback",

  UserViewedZoneDetails = "User viewed zone details",

  UserViewedHazardDetails = "User viewed hazard details",

  UserClickedDownloadApp = "User clicked download app",
}

export enum AnalyticsCategories {
  User = "user",
}
