import { Position } from "geojson";

import { Hazard, ZoneDetails } from "../../components/Map/types";
import { CacheTimeoutPeriod } from "../../constants";
import { HazardsSortMethod, LocationSelectionType } from "../slices/appSlice";
import { BaseZonehavenApi } from "./baseZonehavenApi";

interface PointOfInterest {
  name: string;
  address: string;
  type: LocationSelectionType;
}

interface EvacuationPointsResponse {
  arrivalPoints: PointOfInterest[];
}

interface TrafficControlPointsResponse {
  trafficControlPoints: PointOfInterest[];
}

interface HazardDataSlice {
  hazards: Hazard[];
  nextOffset: number;
  lastPage: boolean;
}

interface ZoneLink {
  name: string;
  url: string;
  note?: string;
  image?: string;
}

type HazardId = string;
type ZoneName = string;
type ZoneId = string;

enum ZonesApiCacheTags {
  ZoneDetails = "zoneDetails",
  TrafficControlPoints = "trafficControlPoints",
  EvacuationPoints = "evacuationPoints",
}

const ZonesApiWithTags = BaseZonehavenApi.enhanceEndpoints({
  addTagTypes: [
    ZonesApiCacheTags.ZoneDetails,
    ZonesApiCacheTags.EvacuationPoints,
    ZonesApiCacheTags.TrafficControlPoints,
  ],
});

export const ZonesApi = ZonesApiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchZoneDetails: builder.query<
      ZoneDetails | undefined,
      Position | ZoneName
    >({
      query: (params) => {
        return `zone?zone_id=${params}`;
      },
      providesTags: [ZonesApiCacheTags.ZoneDetails],
    }),
    fetchZoneDetailsWithCoords: builder.query<
      ZoneDetails | undefined ,
      Position | ZoneName
    >({
      //Overriding default baseQuery and adding inline baseQuery to handle error response
      async queryFn(params, api, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `${process.env.REACT_APP_API_BASE_URL}zone?coordinates=${params[1]},${params[0]}`,
          method: 'GET',
        });    
        if (response.error?.status === 404) {
          // don't refetch on 404
          return { data: response.data as ZoneDetails};
        }    
        if (response.error) {
          // but refetch on another error
          return { error: response.error };
        }    
        return { data: response.data as ZoneDetails};
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: { zone } } = await queryFulfilled
          //Update cache for zone.identifier with the retrieved zone data
          const patchResult = dispatch(
            ZonesApi.util.updateQueryData('fetchZoneDetails', zone.identifier, (draft) => {
              Object.assign(draft, zone)
            })
          )
        } catch {}
      },
      providesTags: [ZonesApiCacheTags.ZoneDetails]
    }),
    fetchHazards: builder.query<
      HazardDataSlice,
      { location?: Position; offset: number; sortBy: HazardsSortMethod }
    >({
      query: (params) => {
        const urlParams = [];

        urlParams.push(`sortby=${params.sortBy}`);
        urlParams.push(`offset=${params.offset}`);

        if (params.sortBy === HazardsSortMethod.Distance && params.location) {
          urlParams.push(
            `location=${params.location[1].toFixed(2)},${params.location[0].toFixed(2)}`
          );
        }

        return `/hazards?${urlParams.join("&")}`;
      },
    }),
    fetchHazardDetails: builder.query<Hazard, HazardId>({
      query: (hazardId) => {
        return `/hazards/${hazardId}`;
      },
    }),
    fetchZoneEvacuationPoints: builder.query<EvacuationPointsResponse, ZoneId>({
      query: (zoneId) => `/zone/${zoneId}/arrivalpoints`,
      providesTags: [ZonesApiCacheTags.EvacuationPoints],
    }),
    fetchZoneTrafficControlPoints: builder.query<
      TrafficControlPointsResponse,
      ZoneId
    >({
      query: (zoneId) => `/zone/${zoneId}/trafficcontrolpoints`,
      providesTags: [ZonesApiCacheTags.TrafficControlPoints],
    }),
    fetchWeather: builder.query<any, Position>({
      query: (location) => `/weather/${location[0]},${location[1]}`,
    }),
    fetchZoneLinks: builder.query<ZoneLink[], ZoneId>({
      query: (zoneId) => `/zone/${zoneId}/links`,
      transformResponse: (response: { links: ZoneLink[] }) => {
        return response.links;
      },
    }),
    fetchMapDataUpdates: builder.query<{ layersToUpdate: string[] }, void>({
      queryFn: () => ({ data: { layersToUpdate: null } }),
      async onCacheEntryAdded(
        _,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          await cacheDataLoaded;

          const invalidateTagWithTimeout = (tag) => {
            setTimeout(
              () => dispatch(ZonesApi.util.invalidateTags([tag])),
              CacheTimeoutPeriod
            );
          };

          const listener = (event: MessageEvent) => {
            if (event.data === "pong") {
              return;
            }

            const data = JSON.parse(event.data) as {
              statusUpdate: boolean;
              arrivalPointUpdate: boolean;
              tcpUpdate: boolean;
              splitMergeUpdate: boolean;
            };

            if (data.statusUpdate || data.splitMergeUpdate) {
              invalidateTagWithTimeout(ZonesApiCacheTags.ZoneDetails);
            }

            if (data.tcpUpdate) {
              invalidateTagWithTimeout(ZonesApiCacheTags.TrafficControlPoints);
            }

            if (data.arrivalPointUpdate) {
              invalidateTagWithTimeout(ZonesApiCacheTags.EvacuationPoints);
            }

            updateCachedData((draft) => {
              draft = { layersToUpdate: Object.keys(data) };
              return draft;
            });
          };

          let intervalId: ReturnType<typeof setInterval> | null = null;
          let isAlive = false;

          const connectWs = () => {
            if (isAlive) {
              return;
            }

            const ws = new WebSocket(
              `${process.env.REACT_APP_ZONE_STATUS_UPDATES_WEBSOCKET}?type=CEI`
            );

            ws.onopen = () => {
              isAlive = true;

              const PintIntervalInMinutes = 9;
              const PingIntervalInMilliseconds =
                PintIntervalInMinutes * 60 * 1000;

              intervalId = setInterval(() => {
                ws.send("ping");
              }, PingIntervalInMilliseconds);
            };

            ws.onmessage = listener;

            ws.onclose = () => {
              // eslint-disable-next-line no-console
              console.warn("---- Websocket closed ----");

              if (intervalId) {
                clearInterval(intervalId);
              }

              isAlive = false;
            };

            ws.onerror = (error) => {
              // eslint-disable-next-line no-console
              console.warn("---- Websocket error ----", error);

              isAlive = false;
            };
          };

          window.addEventListener("online", connectWs);

          connectWs();
        } catch (error) {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded` (in which case `cacheDataLoaded` will throw)
          console.warn(
            "Something went wrong connecting to the websocket. Error:",
            error
          );
        }

        await cacheEntryRemoved;
      },
    }),
  }),
});

export const {
  useFetchHazardsQuery,
  useFetchWeatherQuery,
  useFetchZoneLinksQuery,
  useFetchZoneDetailsQuery,
  useFetchZoneDetailsWithCoordsQuery,
  useFetchHazardDetailsQuery,
  useFetchZoneTrafficControlPointsQuery,
  useFetchMapDataUpdatesQuery,
  useFetchZoneEvacuationPointsQuery,
} = ZonesApi;
