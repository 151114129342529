import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const PING_RESOURCE = "./ping.txt";
const TIMEOUT_TIME_MS = 10000;
const ONLINE_POLLING_INTERVAL_MS = 10000;

const timeout = (time: number, promise: Promise<Response>) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error("Request timed out.")), time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  if (!navigator.onLine) {
    return navigator.onLine;
  }

  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: "GET",
        signal,
      })
    );
    return true;
  } catch (error) {
    console.warn(error);
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = createContext(true);

export const OnlineStatusProvider: FunctionComponent = ({
  children,
}: PropsWithChildren<{}>) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    window.addEventListener("offline", () => setOnlineStatus(false));

    // Add polling incase of slow / very unstable connection
    const id = setInterval(() => checkStatus(), ONLINE_POLLING_INTERVAL_MS);

    return () => {
      window.removeEventListener("offline", () => setOnlineStatus(false));

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};
