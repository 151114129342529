import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Position, BBox } from 'geojson';

import { appMapConfig } from '~/components/Map/config';
import { RequestStatus } from '~/constants';

const { zoom, latitude, longitude } = appMapConfig.initialViewState;

type MapState = {
  data: {
    viewport: {
      center: Position;
      zoom: number;
      bbox: null | BBox;
    };
  };
  requests: {
    getCurrentLocationThunkStatus: RequestStatus;
    getCurrentLocationThunkError: Error | null;
  };
};

const initialState: MapState = {
  data: {
    // this viewport object is only used to sync the map's viewport with the redux store
    // the coordinates that will trigger map movement are the ones in the app slice
    viewport: {
      center: [latitude, longitude],
      zoom,
      bbox: null,
    },
  },
  requests: {
    getCurrentLocationThunkStatus: RequestStatus.Idle,
    getCurrentLocationThunkError: null,
  },
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setZoomState(state, action: PayloadAction<number>) {
      state.data.viewport.zoom = action.payload;
    },
    setCenterPositionState: (state, action: PayloadAction<Position>) => {
      state.data.viewport.center = action.payload;
    },
    setBboxState: (state, action: PayloadAction<BBox>) => {
      state.data.viewport.bbox = action.payload;
    },
  },
});

export const MapActions = mapSlice.actions;
