import { Box, Divider as MuiDivider, Typography } from "@mui/material";

import { PropsWithSx } from "../../utils";

import { H3Styled } from "../common/Styled/H3Styled"

interface DividerProps {
  label?: string;
}

export const Divider = ({ label, sx, ...props }: PropsWithSx<DividerProps>) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...sx,
      }}
      {...props}
    >
      {label && (
        <H3Styled variant="h2" sx={{ mr: 1 }}>
          {label}
        </H3Styled>
      )}
      <MuiDivider sx={{ display: "flex", flex: 1 }} />
    </Box>
  );
};
