import { CSSTransition } from "react-transition-group";

import "./transition.css";

interface Props {
  in?: boolean;
  timeout?: number;
  classNames?: string;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  children: React.ReactNode;
}

export const Transition = ({
  in: show,
  mountOnEnter = true,
  unmountOnExit = true,
  classNames = "transition-fade",
  timeout = 300,
  children,
  ...props
}: Props) => {
  return (
    <CSSTransition
      in={show}
      timeout={timeout}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      classNames={classNames}
      {...props}
    >
      {children}
    </CSSTransition>
  );
};
