import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { components, ValueContainerProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  trackUserSearchedAddressOutsideZone,
  trackUserSearchedAddressWithinZone,
  trackUserSearchedZoneById,
} from 'analytics/fns';
import { getZoneIdentifierFromSearchResult, isZoneId } from 'utils';
import { isFetchBaseQueryError } from 'utils/typesUtils';
import { Loader } from '~/components/Loader';

import { getCoordsForSearchSuggestion, getLocations } from '../../api';
import Icon from '../../components/Icon';
import { Color, NotFound } from '../../constants';
import { I18n } from '../../i18n';
import {
  useAppDispatch,
  useAppSelector,
  useFetchZoneDetailsQuery,
  useFetchZoneDetailsWithCoordsQuery,
} from '../../redux-rtk';
import {
  AppActions,
  LocationSelectionType,
} from '../../redux-rtk/slices/appSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <Icon name="Search" color={Color.LeafGreen} size={24} />}
        <form id="search">
        {(children as any).map((child) => {
          if(child?.key === 'placeholder') {
            return <label htmlFor='react-select-3-input' className='search-zone__placeholder'>{child.props.children}</label>
          } else {
            return child;
          }
        })}
        </form>
      </components.ValueContainer>
    )
  );
};

const SearchZoneInput = () => {
  const [inputValue, setInputValue] = useState('');
  const [toMenuIsOpen, setToMenuIsOpen] = useState(false);
  const [selectedCoords, setSelectedCoords] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedSearchTerm, setSelectedSearchTerm] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // Get the map's lng / lat as a comma-separated string. We use this as a bias when searching, and
  // also as a cache option for the AsyncSelect, so that it forces a refresh when these change
  const mapCenter = useAppSelector(state => state.map.data.viewport.center);

  const handleToFieldInputChange = (value, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setInputValue(value);

      if (value.trim() === '') {
        setSelectedCoords(null);
        setSelectedZoneId(null);
      }
    }

    setToMenuIsOpen(!!value.trim().length);
  };

  const onChange = useCallback(async (item, action) => {
    if (action.action === 'clear') {
      return setInputValue('');
    }

    if (isZoneId(item.value)) {
      setSelectedZoneId(item.value);
      // If the search looks like a zone, we jump straight to it without doing any additional querying
      trackUserSearchedZoneById(item.value);
    }
    // Using the selected item & the map's current center, get the actual coordinates for this item
    // NOTE that the `mapCenter` is expected to have not changed since the user last typed something
    // in to the search box & we re-queried the suggestions
    // I believe this to be safe, because the way our application works, if the user started
    // interacting with the map (or anything else on the page which might cause the map's center to
    // change), the search box will hide, and the user would need to re-type something to trigger
    // fresh suggestion results with a new map center anyways.
    const coords = await getCoordsForSearchSuggestion(item, mapCenter);

    setSelectedCoords(coords);
    return setSelectedSearchTerm(item.label);
  }, []);

  const {
    data: zoneDetails,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useFetchZoneDetailsWithCoordsQuery(selectedCoords, {
    skip: !selectedCoords,
  });

  const {
    data: zoneIdDetails,
    isError: zoneIdIsError,
    isSuccess: zoneIdIsSuccess,
    isLoading: zoneIdIsLoading,
  } = useFetchZoneDetailsQuery(selectedZoneId?.toUpperCase(), {
    skip: !selectedZoneId,
  });

  useEffect(() => {
    if (isSuccess && zoneDetails) {
      trackUserSearchedAddressWithinZone(zoneDetails.zone?.identifier);
      const searchTerm = encodeURI(selectedSearchTerm);
      return navigate(
        `/zones/${zoneDetails.zone?.identifier}?searchTerm=${searchTerm}&coords=${selectedCoords}`,
      );
    }
    if (zoneIdIsSuccess && zoneIdDetails) {
      trackUserSearchedZoneById(selectedZoneId);
      const searchTerm = encodeURI(selectedZoneId.toUpperCase());
      return navigate(
        `/zones/${getZoneIdentifierFromSearchResult(
          zoneIdDetails,
          selectedZoneId,
        )}?searchTerm=${searchTerm}`,
      );
    }
  }, [isSuccess, zoneDetails, zoneIdIsSuccess, zoneIdDetails]);

  useEffect(() => {
    if (isError || !zoneDetails) {
      if (
        (isFetchBaseQueryError(error) || !zoneDetails) &&
        selectedSearchTerm
      ) {
        const _error = error as any;
        // TODO: Handle error with error id not error message
        if (_error?.data?.message === "Zone doesn't exist" || !zoneDetails) {
          trackUserSearchedAddressOutsideZone();
        }
      }
      if (selectedCoords) {
        dispatch(
          AppActions.selectLocation({
            type: LocationSelectionType.Position,
            position: [...selectedCoords],
          }),
        );
      }
    }

    if (
      selectedSearchTerm &&
      ((zoneIdIsError && !zoneIdIsLoading) || (isError && !isLoading))
    ) {
      const searchTerm = encodeURI(selectedSearchTerm);
      return navigate(`/zones/${NotFound}?searchTerm=${searchTerm}`);
    }

    if (isError || zoneIdIsError) {
      // Something went wrong; reset state to nulls
      setSelectedCoords(null);
      setSelectedZoneId(null);
      setSelectedSearchTerm(null);
    }
  }, [
    isError,
    zoneDetails,
    selectedSearchTerm,
    isLoading,
    zoneIdIsError,
    zoneIdDetails,
    zoneIdIsLoading,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        mb: 3,
        px: 2.5,
        '& .sb-icon-div': {
          position: 'absolute',
          left: '10px',
        },
      }}
      role="search"
    >
      <AsyncSelect
        className="search-zone"
        classNamePrefix="search-zone"
        isClearable
        menuIsOpen={toMenuIsOpen}
        inputValue={inputValue}
        onInputChange={handleToFieldInputChange}
        onChange={onChange}
        isSearchable={true}
        cacheOptions={mapCenter}
        tabSelectsValue
        loadOptions={async searchText => {
          // TODO: Move this to RTK
          const locations = await getLocations(searchText, mapCenter);
          const options = locations.map(location => {
            return {
              value: location.magicKey,
              label: location.text,
            };
          });
          // Special case; if the user has entered what looks to be a valid zone identifier, we prepend that to the list
          if (isZoneId(searchText)) {
            return [
              {
                value: searchText,
                label: `${I18n.t('zones.zone')}: ${searchText.toUpperCase()}`,
              },
            ].concat(options);
          }
          return options;
        }}
        placeholder={I18n.t('inputsPlaceholder.searchAddressOrZone')}
        styles={{
          valueContainer: base => ({
            ...base,
            paddingLeft: 40,
            height: '50px',
            "& > form": {
              display: 'flex',
              alignItems: 'center',
              '& > .search-zone__placeholder': {
                color: 'rgb(128, 128, 128)',
                marginLeft: '2px'
              },
              '& > .search-zone__input-container': {
                position: 'absolute',
              }
            }
          }),
          control: (base, state) => {
            return {
              ...base,
              bottom: state.menuIsOpen ? '-2px' : '0px',
              border: state.menuIsOpen
                ? '2px solid'
                : `1px solid ${theme.palette.grey[300]}`,
              borderRadius: state.menuIsOpen ? '30px 30px 0px 0px' : '30px',
              borderColor: state.isFocused
                ? state.menuIsOpen
                  ? `${theme.palette.primary.light} ${theme.palette.primary.light} ${theme.palette.grey[50]} ${theme.palette.primary.light} !important`
                  : theme.palette.primary.light
                : theme.palette.primary.light,
              boxShadow: theme.shadows[2],
              '&:hover': {
                borderColor: state.isFocused
                  ? state.menuIsOpen
                    ? `${theme.palette.primary.light} ${theme.palette.primary.light} ${theme.palette.grey[50]} ${theme.palette.primary.light}`
                    : theme.palette.primary.light
                  : theme.palette.primary.light,
              },
            };
          },
          menu: base => ({
            ...base,
            borderRadius: '0px 0px 30px 30px',
            marginTop: '0px',
            borderColor: theme.palette.primary.light,
            borderStyle: 'solid',
            borderWidth: '0px 2px 2px 2px',
            boxShadow: theme.shadows[1],
          }),
          input: base => ({
            ...base,
            display: 'flex',
          }),
          menuList: base => ({
            ...base,
            marginBottom: '25px',
          }),
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader />,
          ValueContainer,
        }}
      />
    </Box>
  );
};

export default SearchZoneInput;
