import { Android, Apple } from '@mui/icons-material';
import { Alert, Box, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocalStorage } from 'usehooks-ts';

import {
  trackUserClickedDownloadApp,
  trackUserFollowedZone,
} from 'analytics/fns';
import { LocalStorageFollowedZone, LocalStorageKeys } from 'types';
import { getZoneIdFromZoneName } from 'utils';
import { PrimaryButton, TextButton } from '~/components/common/Buttons';
import { Divider } from '~/components/Divider';

import { I18n } from '../../i18n';
import { Modal } from '../Modal/Modal';

interface FollowZoneModalProps {
  zoneId: string;
  isOpen: boolean;
  onClose: () => void;
}

type FormValues = {
  alias: string;
};

export const FollowZoneModal = ({
  isOpen,
  onClose,
  zoneId,
}: FollowZoneModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [, setFollowedZones] = useLocalStorage<LocalStorageFollowedZone[]>(
    LocalStorageKeys.FollowedZones,
    [],
  );

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    data => {
      reset();

      setFollowedZones(prev => {
        const newFollowedZone: LocalStorageFollowedZone = {
          alias: data.alias,
          zoneId,
          dateFollowed: dayjs().toString(),
          orderPosition: prev.length,
        };
        return [...prev, newFollowedZone];
      });

      trackUserFollowedZone(zoneId);

      enqueueSnackbar(
        I18n.t('followZoneModal.nowFollowingZoneWithId', {
          id: getZoneIdFromZoneName(zoneId),
        }),
        {
          variant: 'success',
        },
      );

      return onClose();
    },
    [zoneId],
  );

  return (
    <Modal
      isOpen={isOpen}
      hasCloseButton
      onClose={() => {
        reset();
        onClose();
      }}
      CardSx={{
        flexGrow: 1,
        mx: [3, 3, 'auto'],
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mr: 4 }}>
        <Typography variant="h2">{I18n.t('zoneDetails.followZone')}</Typography>
        <Typography variant="h2">{getZoneIdFromZoneName(zoneId)}</Typography>
      </Box>

      <Alert severity="info" sx={{ mt: 2 }}>
        {I18n.t('followZoneModal.realTimeUpdates')}
      </Alert>

      <Box
        sx={{
          mt: 1,
          gap: 0.5,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <TextButton
          endIcon={<Apple sx={{ position: 'relative', bottom: 3, right: 2 }} />}
          label={I18n.t('common.downloadIOS')}
          onClick={() => {
            trackUserClickedDownloadApp();
          }}
        />
        <TextButton
          endIcon={
            <Android sx={{ position: 'relative', bottom: 2, right: 2 }} />
          }
          label={I18n.t('common.downloadAndroid')}
          onClick={() => {
            trackUserClickedDownloadApp();
          }}
        />
      </Box>

      <Divider sx={{ mt: 1, mb: 3 }} />

      <Typography variant="h3">
        {I18n.t('followZoneModal.giveZoneAlias', {
          id: getZoneIdFromZoneName(zoneId),
        })}
      </Typography>

      <TextField
        sx={{ mt: 1.5 }}
        id="outlined-helperText"
        label={I18n.t('followZoneModal.zoneAlias')}
        error={!!errors.alias}
        placeholder={I18n.t('followZoneModal.aliasPlaceholder')}
        helperText={I18n.t('followZoneModal.aliasHelperText')}
        {...register('alias', { required: false, minLength: 2 })}
      />

      <Box
        sx={{
          mt: 2,
          gap: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <TextButton
          label={I18n.t('followZoneModal.skipAlias')}
          isEndIconVisible={false}
          onClick={handleSubmit(onSubmit)}
        />

        <PrimaryButton
          label={I18n.t('followZoneModal.saveAlias')}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </Modal>
  );
};
