import {
  GeolocateControlProps,
  NavigationControlProps,
  ScaleControlProps,
} from 'react-map-gl/maplibre';

export interface ControlConfig {
  scaleControl: ScaleControlProps;
  navigationControl: NavigationControlProps;
  geolocateControl: GeolocateControlProps;
}

export const controlConfig: ControlConfig = {
  scaleControl: {
    position: 'bottom-right',
    maxWidth: 80,
    unit: 'imperial',
  },
  navigationControl: {
    position: 'bottom-right',
    showCompass: false,
  },
  geolocateControl: {
    position: 'bottom-right',
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: false,
  },
};
