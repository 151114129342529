export enum Color {
  DarkBlue = "#19213e",
  WaterBlue = "#1599d8",
  UniformBlue = "#0e4e9a",
  LeafGreen = "#00857C",
  PrimaryGreen = "#08C296",
  FireRed = "#e32d57",
  DarkRed = "#a72846",
  Orange = "#f2994a",
  White = "#ffffff",
  Grey50 = "#f4f4f5",
  Grey100 = "#e8e9ec",
  Grey200 = "#d1d3d8",
  Grey300 = "#babcc5",
  Grey500 = "#8c909e",
  Grey600 = "#757a8b",
  BodyText = "#121620",
}
