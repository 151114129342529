import { IUserLocation, LocationOrigin } from "../types";

const bboxRegex = /BOX\(([^ ]+) ([^ ]+),([^ ]+) ([^ ]+)\)/g;

export const parseBBOX = (
  bboxString?: string
): [[number, number], [number, number]] | null => {
  if (!bboxString) {
    return null;
  }
  const [, swLat, swLon, neLat, neLon] = [...bboxString.matchAll(bboxRegex)][0];

  return [
    [parseFloat(swLat), parseFloat(swLon)],
    [parseFloat(neLat), parseFloat(neLon)],
  ];
};

/**
 *
 * @returns a promise that always resolves to either IUserLocation (coordinates and origin) OR null.
 */
export const getBrowserLocation = (): Promise<IUserLocation | null> => {
  return new Promise((resolve) => {
    if (navigator.geolocation?.getCurrentPosition) {
      return navigator.geolocation.getCurrentPosition(
        (currentPosition) => {
          const { latitude, longitude, accuracy } = currentPosition.coords;

          resolve({
            accuracyInMeters: accuracy,
            coordinates: [latitude, longitude],
            origin: LocationOrigin.Browser,
          });
        },
        (error) => {
          console.warn("Error while getting browser location: ", error);
          // TODO: Track error w/ sentry
          resolve(null);
        },
        { maximumAge: 5000, timeout: 12000, enableHighAccuracy: true }
      );
    }

    return resolve(null);
  });
};
