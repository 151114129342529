import { ThemeOptions } from "@mui/material/styles";

import { TypographyTheme } from "./typographyTheme";

// Reference: https://mui.com/material-ui/customization/default-theme/
export const getBaseTheme = () => ({
  ...TypographyTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 740,
      lg: 1024,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      light: "#08C296",
      main: "#00857C",
      dark: "#005D56"
    },
    secondary: {
      main: "#19213e",
      light: "#0AA5E1",
      pale: "#0978C8",
      dark: "#065289",
    },
    danger: {
      main: "#E32D57"
    },
    error: {
      main: "#FF4D4F"
    },
    grey: {
      25: "#00857c0a",
      50: "#f4f4f5",
      100: "#e8e9ec",
      200: "#d1d3d8",
      300: "#babcc5",
      500: "#8c909e",
      600: "#5A5B66",
    },
    divider: "#babcc5",
    common: {
      black: "#141414",
      white: "#ffffff",
    },
    text: {
      primary: "#19213e",
      secondary: "#757a8b",
      tertiary: "#8c909e",
    },
  },
  shadows: [
    "none",
    "0px 10px 15px rgb(25 33 62 / 15%)",
    "0px 6px 8px rgba(90, 91, 102, 0.1)",
    ...Array(22).fill("none"),
  ] as ThemeOptions["shadows"],
  shape: {
    borderRadius: 6,
  },
  spacing: 8,
} as ThemeOptions);
