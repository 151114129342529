import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";

import { PropsWithSx } from "../../utils/sxUtils";

export interface CardProps extends BoxProps {
  padding?: number;
  hasShadow?: boolean;
  backgroundColor?: "white" | "grey";
}

export const Card = ({
  children,
  padding = 2, // default container padding
  hasShadow = true,
  backgroundColor = "white",
  sx,
  ...props
}: PropsWithChildren<PropsWithSx<CardProps>>) => {
  return (
    <Box
      sx={{
        padding,
        borderRadius: 2,
        boxShadow: hasShadow ? 1 : 0,
        backgroundColor: backgroundColor === "white" ? "white" : "grey.50",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
