import { useDetectAdBlock } from "adblock-detect-react";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useLocation } from "react-router-dom";

import { isZoneId } from "utils";

import { isHazardId } from "./../utils/zoneUtils";
import {
  logAnalyticsEvent,
  trackUserViewedHazardDetails,
  trackUserViewedZoneDetails,
} from "./fns";
import { HazardTypes } from "../types";

export const useAnalytics = () => {
  const currentPage = useLocation().pathname;

  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      return;
    }

    if (!hotjar.initialized()) {
      hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 6);
    }
  }, [hotjar.initialized(), adBlockDetected]);

  useEffect(() => {
    // dont track w/ adblock because it can throw an error
    // also dont track '/' because it redirects to /search
    if (!adBlockDetected && currentPage !== "/") {
      const currentPageWithoutIds = currentPage.split("/")[1];
      const pageId = currentPage.split("/")[2];

      logAnalyticsEvent({
        eventName: "pageview",
        eventParams: {
          page: `/${currentPageWithoutIds}`,
        },
      });

      if (!pageId) {
        return;
      }

      if (isZoneId(pageId)) {
        trackUserViewedZoneDetails(pageId);
      }

      if (isHazardId(pageId)) {
        trackUserViewedHazardDetails({
          hazardId: pageId,
          hazardType: HazardTypes.Fire,
        });
      }
    }
  }, [currentPage, adBlockDetected]);
};
