import { BaseZonehavenApi } from './baseZonehavenApi';

const MiscApi = BaseZonehavenApi.injectEndpoints({
  overrideExisting: false,
  endpoints(builder) {
    return {
      createFeedbackMessage: builder.mutation({
        query: feedback => ({
          url: '/feedback',
          method: 'POST',
          body: { ...feedback, source: 'WEB' },
        }),
      }),
    };
  },
});

export const { useCreateFeedbackMessageMutation } = MiscApi;
