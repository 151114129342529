import { MapDataEvent } from 'maplibre-gl';
import { useCallback, useEffect, useState } from 'react';
import { MapRef } from 'react-map-gl/maplibre';
import {
  ArrivalPointId,
  arrivalPointTypeMapping,
  tcpTypes,
  TrafficControlPointId,
} from '~/components/Map/config';
import { ArrivalPoint, RoadClosure } from '~/components/Map/types';
import { LocationSelection } from '~/redux-rtk/slices/appSlice';
import { getApOrTcpFromMapData } from '~/utils';

export const useGetSelectedApTcpData = ({
  selectedLocation,
  map,
  type,
}: {
  selectedLocation: LocationSelection;
  map: MapRef;
  type: 'arrivalPoint' | 'trafficControlPoint';
}) => {
  const [selectedData, setSelectedData] = useState<
    ArrivalPoint | RoadClosure
  >();
  // Create a function that will handle updates to this map layer.
  // We need to do this because the vector tile layer may update at any moment without being
  // "prompted" by anything else, meaning the map may start displaying up to date while our popup
  // still displays old, stale data
  const updateDataFromMap = useCallback(
    (e: MapDataEvent & { sourceId?: string }) => {
      // Ignore map updates for other sources
      if (e.sourceId === 'ap_tcp_group') {
        const sourceLayer =
          type === 'arrivalPoint' ? ArrivalPointId : TrafficControlPointId;
        // We got updated data for the source layer of the currently-selected feature; query for
        // the matching feature that the map has
        const updatedMapData = map.querySourceFeatures(e.sourceId, {
          sourceLayer: sourceLayer,
          filter: [
            '==',
            // // APs & TCPs have different property keys for their id
            type === 'arrivalPoint' ? 'arrival_point_id' : 'id',
            Number(selectedLocation.id), // On the map layers, these are always served as numerical
          ],
        });
        // We may not have a result if we've just cleared the map & are still re-querying for data
        if (updatedMapData.length > 0) {
          // Update the data
          setSelectedData(prevData =>
            getApOrTcpFromMapData(updatedMapData[0], prevData, sourceLayer),
          );
        }
      }
    },
    [type, map, selectedLocation?.id],
  );

  useEffect(() => {
    if (type === 'arrivalPoint') {
      // APs
      if (
        selectedLocation?.type &&
        (arrivalPointTypeMapping as string[]).includes(selectedLocation.type)
      ) {
        setSelectedData({
          id: selectedLocation.id,
          address: (selectedLocation.details as ArrivalPoint).address,
          name: (selectedLocation.details as ArrivalPoint).name,
          type: selectedLocation.type,
        });
        // If the map data changes, update the state
        map?.on('data', updateDataFromMap);
      } else if (selectedData) {
        setSelectedData(undefined);
      }
    } else {
      // TCPs
      if (
        selectedLocation?.details &&
        (tcpTypes as string[]).includes(selectedLocation.type)
      ) {
        setSelectedData({
          ...(selectedLocation.details as RoadClosure),
          type: selectedLocation.type,
        });
        // If the map data changes, update the state
        map?.on('data', updateDataFromMap);
      } else if (selectedData) {
        setSelectedData(undefined);
      }
    }
    return () => {
      // Always clean up the event handler when the useEffect is cleaning up
      // Note that this is the "old" version of the `updateDataFromMap` callback function; when a
      // new one is created, this useEffect cleanup gets called with the previous version, allowing
      // us to remove that event handler before setting up a new one with the new callback function
      map?.off('data', updateDataFromMap);
    };
  }, [type, selectedLocation, updateDataFromMap]);

  return selectedData;
};
