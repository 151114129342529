import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

import { PropsWithSx } from "utils";

export const Content = ({
  sx,
  children,
  ...props
}: PropsWithChildren<PropsWithSx<{}>>) => {
  return (
    <Box
      sx={{
        px: 3,
        pt: 5,
        mx: -3,
        gap: 3,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "grey.50",
        borderTopLeftRadius: "55px",
        borderTopRightRadius: "55px",
        ...sx,
      }}
      {...props}
    >
      {children}

      {/* We need this box for gap: 3 to apply a hacky padding bottom to the layout */}
      <Box />
    </Box>
  );
};
