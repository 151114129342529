import { useEffect, useState } from "react";

import { StyledIcon } from "./styles";

export const Icon = ({ name, size = 12, color = "red", isDecorative = false }) => {
  const [iconUrl, setIconUrl] = useState(null);

  const iconFetch = async (iconName) => {
    await import(`../../../assets/icons/${iconName}.svg`)
      .then((data) => setIconUrl(data.default))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    iconFetch(name.toLowerCase().replace(/\s+/g, ""));
  }, []);

  if (!iconUrl) {
    return null;
  }

  return (
    <StyledIcon size={size} color={color} className={`icon ${name}`}>
      <img alt={isDecorative ? "" : `${name} svg`} src={iconUrl} role="img"/>
    </StyledIcon>
  );
};
