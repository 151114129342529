import { Typography } from "@mui/material";

import { I18n } from "~/i18n";
import { Card } from "~/layouts/Card";
import { PropsWithSx } from "~/utils";

import Icon from "../Icon";

interface StateOrCurrentLocationChipProps {
  isUserZone?: boolean;
  stateAbbreviation: string;
}

export const StateOrCurrentLocationChip = ({
  sx,
  isUserZone,
  stateAbbreviation,
}: PropsWithSx<StateOrCurrentLocationChipProps>) => {
  return (
    <Card
      padding={1}
      hasShadow={false}
      backgroundColor="grey"
      sx={{
        gap: 1,
        borderRadius: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "fit-content",
        ...sx,
      }}
    >
      <Icon
        size={16}
        color="#000"
        className="icon"
        name={isUserZone ? "Target" : "LocationShow"}
      />
      <Typography variant="body2">
        {isUserZone
          ? I18n.t("myZones.currentLocation")
          : `State - ${stateAbbreviation}`}
      </Typography>
    </Card>
  );
};
