import { Box } from "@mui/material";
import Lottie from "react-lottie";

import { PropsWithSx } from "utils";

import * as loadingAnimation from "../../assets/lotties/loading.json";

interface Props {
  height?: number;
  width?: number;
  [key: string]: unknown;
}
export const Loader = ({ height = 25, width = 60, sx }: PropsWithSx<Props>) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Lottie
        width={width}
        height={height}
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid",
          },
        }}
      />
    </Box>
  );
};
