import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { I18n } from 'i18n';
import { PropsWithSx } from 'utils';
import { ReactComponent as MobileAppIcon } from "~/assets/icons/mobile-app.svg";
import { useIsMobileDevice } from '~/hooks';

export const DownloadAppCTA = ({ sx }: PropsWithSx<{}>) => {
  const isMobile = useIsMobileDevice();

  return (
    <Link
      to="/download">
        <Box
        sx={{
          width: 'inherit',
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          alignItems: 'center',
          ...sx,
        }}
      >
        <Box sx={{ width: 44, height: 44, mr: isMobile ? 2 : 6 }}>
          <MobileAppIcon role="img" aria-label="genasys logo"/>
        </Box>

        <Box
          sx={{
            height: '100%',
            display: 'inline',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ display: ['block', 'block', 'inline'] }}
          >
            {I18n.t('marketing.downloadForAdditionalFeatures', { appName: I18n.t('common.appName') })}{' '}
            <Typography
              sx={{
                display: ['block', 'block', 'inline'],
                textDecoration: 'underline',
                color: 'primary.main',
              }}
              aria-label="Learn More about the Genasys Protect App"
            >
              {I18n.t('marketing.learnMore', { appName: I18n.t('common.appName')})}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};
