import { IUserLocation, LocationOrigin } from '../types';
import { wait } from './generalUtils';

const SCRIPT_DOCUMENT_ID = 'geotargetly';
const SCRIPT_BASE_URL = `https://g1386590346.co/gl?id=${process.env.REACT_APP_GEOTARGETLY_ID}`;

class GeotargetlyHelper {
  /**
   * Checks if the script has already been inserted in the document.
   * @returns *true* if the script is present. *false* otherwise.
   */
  private isInjected() {
    return !!document.getElementById(SCRIPT_DOCUMENT_ID);
  }

  /**
   * Loads the Geotargetly script in the document.
   * @param originUrl url used as origin of the request.
   */
  public initialize(originUrl: string) {
    if (!this.isInjected()) {
      const script = document.createElement('script');
      script.id = SCRIPT_DOCUMENT_ID;
      script.async = true;
      script.src = `${SCRIPT_BASE_URL}&refurl=${document.referrer}&winurl=${originUrl}`; //encodeURIComponent(window.location)
      document.body.appendChild(script);
    }
  }

  /**
   * Returns the current *UserLocation* provided by Geotargetly.
   * @param maxWaitTime Maximum time to wait for Geotargetly to respond (in ms).
   * @returns The *UserLocation* object if service is available. *null* otherwise.
   */
  public async getLocation(maxWaitTime = 3000): Promise<IUserLocation | null> {
    this.initialize(
      process.env.REACT_APP_BASE_URL ||
        encodeURIComponent(window.location.toString()),
    );
    let trialCount = 3;
    const waitTime = maxWaitTime / trialCount;

    while (trialCount > 0) {
      if (window.geotargetly_lat?.() && window.geotargetly_lng?.()) {
        return {
          accuracyInMeters: null,
          coordinates: [
            Number(window.geotargetly_lat()),
            Number(window.geotargetly_lng()),
          ],
          origin: LocationOrigin.Geotargetly,
        };
      }
      trialCount--;
      await wait(waitTime);
    }
    console.warn('Geotargetly: Service not available.');
    return null;
  }
}

export const Geotargetly = new GeotargetlyHelper();
