import { Box, Typography } from "@mui/material";

import { I18n } from "../../../i18n";
import { TextButton } from "../../common/Buttons";

interface Props {
  title?: string;
  description: string;
  onView: () => void;
  iconSrc?: string;
}

export const PointOfInterest = ({
  title,
  description,
  onView,
  iconSrc,
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {iconSrc && (
        <Box
          sx={{
            height: 24,
            width: 24,
            mr: 1,
          }}
          component={"img"}
          src={iconSrc}
        />
      )}

      <Box>
        {title && (
          <Typography variant="body1" fontWeight={"bold"}>
            {title}
          </Typography>
        )}
        <Typography variant="body2">{description}</Typography>

        <TextButton
          onClick={onView}
          sx={{ position: "relative", right: 10 }}
          label={I18n.t("zoneDetails.viewOnMap")}
        />
      </Box>
    </Box>
  );
};
