import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { geoserver } from '../../utils';

// This is the base API handler for map related calls.
// Put any fetch calls in in mapApi.ts
// This file should only be used for configuration (i.e. setting headers or keys)
export const BaseMapApi = createApi({
  reducerPath: 'mapApi',
  baseQuery: fetchBaseQuery({
    baseUrl: geoserver.WFS,
    prepareHeaders(headers) {
      headers.set('Authorization', `Basic ${geoserver.Creds}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
