import { motion } from "framer-motion";

interface Props {
  exit?: object;
  type?: string;
  initial?: object;
  animate?: object;
  duration?: number;
  children?: React.ReactNode;
}

export const MotionDiv = ({
  children,
  type = "tween",
  duration = 0.25,
  initial = { opacity: 0 },
  animate = { opacity: 1 },
  exit = { opacity: 0 },
  ...props
}: Props): React.ReactElement => {
  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{
        type,
        duration,
      }}
      {...props}
    >
      <>{children}</>
    </motion.div>
  );
};
