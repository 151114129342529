import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserActions } from "redux-rtk/slices";
import { AppActions, AppSetupSteps } from "redux-rtk/slices/appSlice";

import { FallbackLocation } from "../../constants";
import { IUserLocation, LocationOrigin } from "../../types";
import { getBrowserLocation } from "../../utils";
import { Geotargetly } from "../../utils/geotargetly";
import { RootState } from "../store";

/**
 * RTK thunk to get the current location of the user.
 * It will try to get the provided origin or it will update the current one if null is passed.
 * Order of priority is Browser > Geotargetly > Fallback
 */
type Props = LocationOrigin | null;

export const updateUserLocationThunk = createAsyncThunk<
  IUserLocation,
  Props,
  {
    rejectValue: IUserLocation;
  }
>("user/updateUserLocationThunk", async (forceOrigin, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const currentUserLocation = state.user.data.currentLocation;
    const origin = forceOrigin ?? currentUserLocation.origin;

    /** 1st attempt (browser) */
    if (
      origin === LocationOrigin.Browser ||
      origin === LocationOrigin.Pending
    ) {
      const usersBrowserLocation = await getBrowserLocation();

      if (usersBrowserLocation) {
        return usersBrowserLocation;
      }
    }

    /** 2nd attempt (geotargetly) */
    const usersGeotargetlyLocation = await Geotargetly.getLocation();
    if (usersGeotargetlyLocation) {
      return usersGeotargetlyLocation;
    }

    /** Fallback */
    return FallbackLocation;
  } catch (e) {
    console.warn("Error while updating user coordinates thunk: ", e);
    // TODO: Track error in sentry
    return thunkApi.rejectWithValue(FallbackLocation);
  }
});

export const resetUserLocationPreferences = () => {
  return (dispatch) => {
    dispatch(
      AppActions.removeCompletedAppSetupSteps(
        AppSetupSteps.SetInitialLocationSelection
      )
    );
    dispatch(UserActions.resetUserCurrentLocation());
  };
};
