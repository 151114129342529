import { Box, Typography } from "@mui/material";

import { PropsWithSx } from "../../../../utils";

import { H3Styled } from "../../../common/Styled/H3Styled"

interface TitleProps {
  title: string;
  icon?: React.ReactNode;
}

export const Title = ({ title, icon, sx }: PropsWithSx<TitleProps>) => {
  return (
    <Box
      sx={{
        mb: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <H3Styled variant="h1">{title}</H3Styled>
      <Box sx={{ position: "relative", top: 2, right: 6 }}>{icon}</Box>
    </Box>
  );
};
