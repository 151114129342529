import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  });

  useEffect(() => {
    const updateSize = () =>
      setSize({
        windowHeight: window.visualViewport.height,
        windowWidth: window.visualViewport.width,
      });

    window.addEventListener("resize", updateSize);
    window.addEventListener("load", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
      window.removeEventListener("load", updateSize);
    };
  }, []);

  return size;
};
