import styled from "styled-components";

interface Props {
  size?: number;
  color?: string;
  url?: string;
}
export const StyledIcon = styled.div<Props>`
  padding: 0;
  margin: 0;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: scale(2);
    object-fit: contain;
  }
`;
