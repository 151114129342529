import { IAppMapConfig } from './types';

/**
 * Configuration for other parameters of the map that are not props.
 */
export const appMapConfig: IAppMapConfig = {
  // San Mateo County
  initialViewState: {
    longitude: -122.22856388725677,
    latitude: 37.57996365735285,
    zoom: 14,
  },
  flyTime: 750,
  zoom: {
    position: 14,
    zone: 14,
    hazard: 14,
    evacuationCenter: 14,
    roadClosure: 14,
    trafficControlPoint: 14,
    animalShelter: 14,
  },
};
