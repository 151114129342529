import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { forwardRef, SyntheticEvent, useCallback, useEffect, useState } from "react";

import { PrimaryButton } from "../../../components/common/Buttons";
import { RadioButtonCheckedIcon } from "../../../components/Icon/RadioButtonCheckedIcon";
import { RadioButtonUncheckedIcon } from "../../../components/Icon/RadioButtonUncheckedIcon";
import { I18n } from "../../../i18n";
import { SlidePanel } from "../../../layouts/SlidePanel";
import { useAppDispatch, useAppSelector } from "../../../redux-rtk";
import {
  AppActions,
  HazardsSortMethod,
} from "../../../redux-rtk/slices/appSlice";
import { FocusTrap } from "@mui/base";

type SortOptionsPanelProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const HazardsSortPanel = forwardRef(({
  isOpen,
  onClose,
}: SortOptionsPanelProps, ref) => {
  const selectedValue = useAppSelector(
    (state) => state.app.data.selectedHazardSort
  );
  const [pendingValue, setPendingValue] =
    useState<HazardsSortMethod>(selectedValue);
  const dispatch = useAppDispatch();

  const applyHandler = () => {
    dispatch(AppActions.selectHazardSort(pendingValue));
    onClose();
  };

  const onChangeHandler = (event: SyntheticEvent) => {
    setPendingValue(
      (event.target as HTMLInputElement).value as HazardsSortMethod
    );
  };

  // If other parts of the app (e.g., the logic for parsing URL params) updates the sort method, we also want to update the Pending value in this component
  useEffect(() => {
    setPendingValue(selectedValue);
  }, [selectedValue]);

  const handleKeydown = useCallback((event) => {
    if (event.srcElement.name === "hazard-sort-options") {
      const isArrowDownKeyPressed = event.key === 'ArrowDown' || event.keyCode === 40;
      const isArrowUpKeyPressed = event.key === 'ArrowUp' || event.keyCode === 38;
      if (isArrowDownKeyPressed || isArrowUpKeyPressed) {
        let sortOptions = document.getElementsByName(event.srcElement.name);
        const currentIndex = Array.prototype.indexOf.call(sortOptions, event.srcElement);
        let nextFocussable: HTMLElement;
        if(isArrowDownKeyPressed) {   
          nextFocussable = sortOptions[currentIndex+1] || sortOptions[0];
        } else {
          nextFocussable = sortOptions[currentIndex-1] || sortOptions[sortOptions.length - 1];
        }
        (nextFocussable as HTMLElement)?.focus();
        event.preventDefault();
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    }
  }, [])

  interface RadioItemProps {
    method: HazardsSortMethod;
    label: string;
    checked: boolean;
    children?: React.ReactNode;
  }

  const RadioItem = ({ method, label, checked, children }: RadioItemProps) => {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <FormControlLabel
          value={method}
          label={
            <>
              <Typography variant="body1">{label}</Typography>
              {children}
            </>
          }
          control={
            <Radio
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
              checked={checked}
            />
          }
          labelPlacement="start"
          sx={{ m: 0, width: "100%", justifyContent: "space-between" }}
        />
      </Box>
    );
  };

  return (
    <FocusTrap open={isOpen}>
    <SlidePanel
      isOpen={isOpen}
      onClose={onClose}
      backButtonText={I18n.t("fires.sortPanelBackText")}
      ref={ref}
    >
      <Typography variant="h3" sx={{ mt: 2 }}>
        {I18n.t("fires.sortPanelTitle")}
      </Typography>
      <FormControl
        sx={{ mt: 2, mb: 2, p: 2, backgroundColor: "grey.50", borderRadius: 1 }}
        variant="standard"
      >
        <RadioGroup
          aria-labelledby="hazard-sort-options"
          name="hazard-sort-options"
          onChange={onChangeHandler}
        >
          <RadioItem
            method={HazardsSortMethod.Distance}
            label={I18n.t("fires.sortByDistance")}
            checked={pendingValue === HazardsSortMethod.Distance}
          >
            <Typography variant="caption">
              {I18n.t("fires.sortByDistanceDesc")}
            </Typography>
          </RadioItem>
          <RadioItem
            method={HazardsSortMethod.Name}
            label={I18n.t("fires.sortByName")}
            checked={pendingValue === HazardsSortMethod.Name}
          >
            <Typography variant="caption">
              {I18n.t("fires.sortByNameDesc")}
            </Typography>
          </RadioItem>
          <RadioItem
            method={HazardsSortMethod.Date}
            label={I18n.t("fires.sortByDate")}
            checked={pendingValue === HazardsSortMethod.Date}
          >
            <Typography variant="caption">
              {I18n.t("fires.sortByDateDesc")}
            </Typography>
          </RadioItem>
        </RadioGroup>
      </FormControl>

      <PrimaryButton
        sx={{ width: "100%" }}
        label={I18n.t("fires.sortApply")}
        onClick={applyHandler}
      />
    </SlidePanel>
    </FocusTrap>
  );
});
