import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

import { Card } from "../../../layouts/Card";
import { PropsWithSx } from "../../../utils";

interface HazardDetailCardProps {
  icon?: React.ReactNode;
  title?: string;
  value?: string | number | React.ReactNode;
}

export const HazardDetailCard = ({
  icon,
  title,
  value,
  children,
  sx,
}: PropsWithChildren<PropsWithSx<HazardDetailCardProps>>) => {
  return (
    <Card
      hasShadow={false}
      backgroundColor="grey"
      sx={{ display: "flex", flexDirection: "column", width: "100%", ...sx }}
    >
      {(icon || title || typeof value !== "undefined") && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          {icon}
          {title && (
            <Typography variant="h3" sx={{ ml: 1, textTransform: "uppercase" }}>
              {title}
            </Typography>
          )}
          {typeof value !== "undefined" && (
            <Typography sx={{ flexGrow: 1, textAlign: "right", fontSize: 22 }}>
              {value}
            </Typography>
          )}
        </Box>
      )}
      {children && <Box sx={{ mt: 2 }}>{children}</Box>}
    </Card>
  );
};
