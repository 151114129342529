import { Box, Collapse, Typography } from '@mui/material';
import { useEffect, useState, useRef } from 'react';

import { useOnlineStatus } from '~/hooks';
import { I18n } from '~/i18n';
import { PropsWithSx } from '~/utils';

export const NoInternetConnection = ({ sx }: PropsWithSx<{}>) => {
  const isOnline = useOnlineStatus();

  const [isVisible, setIsVisible] = useState(false);
  const showTimer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (isOnline) {
      showTimer.current = setTimeout(() => setIsVisible(false), 2500);
    } else {
      clearTimeout(showTimer.current);
      setIsVisible(true);
    }
    return () => clearTimeout(showTimer.current);
  }, [isOnline, setIsVisible]);

  return (
    <Collapse
      collapsedSize={0}
      in={isVisible}
      appear={true}
      unmountOnExit
      sx={{ zIndex: 1 }}
    >
      <Box
        sx={{
          height: 16,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme =>
            isOnline ? theme.palette.primary.light : theme.palette.error.light,
          transition: 'all 1s ease',
          ...sx,
        }}
      >
        <Typography color="white" variant="caption">
          {isOnline
            ? I18n.t('common.backOnline')
            : I18n.t('common.noInternetConnection')}
        </Typography>
      </Box>
    </Collapse>
  );
};
