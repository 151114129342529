import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Close } from '@mui/icons-material';
import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { I18n } from '~/i18n';
import { LocalStorageKeys } from '~/types';

export const RebrandingBanner = () => {
  const theme = useTheme();

  const [bannerShown, setBannerShown] = useLocalStorage<boolean>(
    LocalStorageKeys.RebrandingBannerShown,
    false,
  );
  const [isVisible, setIsVisible] = useState(!bannerShown);

  const bannerStyle: CSSProperties = {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  };

  const textStyle: CSSProperties = {
    flexGrow: 2,
    fontSize: 18,
    fontWeight: 500,
    p: 2,
    color: theme.palette.common.white,
    width: '100%',
    textAlign: 'center',
  };
  const linkStyle: CSSProperties = {
    color: theme.palette.common.white,
    textDecoration: 'underline',
  };

  const closeButtonStyle: CSSProperties = {
    width: 40,
    position: 'relative',
    p: 2,
    cursor: 'pointer',
    background: 'none',
    color: theme.palette.common.white,
    border: 'none',
  };

  const closeIconStyle: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 20,
    height: 'auto',
    transform: 'translate(-50%, -50%)',
  };
  const onCloseHandler = () => {
    setIsVisible(false);
    setBannerShown(true);
  };

  return (
    <Collapse
      collapsedSize={0}
      in={isVisible}
      appear={true}
      unmountOnExit
      sx={{ flexShrink: 0, zIndex: 1 }}
    >
      <Box sx={bannerStyle}>
        <Typography sx={textStyle} noWrap={true}>
          {I18n.t('rebranding.announcementIsNow', {
            appName: I18n.t('common.appName'),
          })}{' '}
          <Link
            style={linkStyle}
            to={'/about'}
            aria-label="Learn More about the Genasys Protect App"
          >
            {I18n.t('common.learnMore')}
          </Link>
        </Typography>
        <button
          style={closeButtonStyle}
          onClick={onCloseHandler}
          title="close banner"
        >
          <Close style={closeIconStyle} role="img" titleAccess="close banner" />
        </button>
      </Box>
    </Collapse>
  );
};