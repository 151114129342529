import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import isEmail from 'validator/es/lib/isEmail';

import { trackUserSubmittedFeedback } from 'analytics/fns';
import { isMobileDownloadEnabled } from 'flags';
import { AppleStoreIcon } from '~/components/Icon/AppleStoreIcon';
import { GoogleStoreIcon } from '~/components/Icon/GoogleStoreIcon';

import Mockup from '../../assets/images/feedback-art.png';
import { I18n } from '../../i18n';
import { useCreateFeedbackMessageMutation } from '../../redux-rtk/api/miscApi';
import { PrimaryButton } from '../common/Buttons';
import { Modal, ModalProps } from '../Modal';

export const FeedbackModal = ({ isOpen, onClose }: ModalProps) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      comply: false,
    },
  });

  const isNotAnEmergency = watch("comply");
  
  const [createFeedback, result] = useCreateFeedbackMessageMutation();

  const onSubmitHandler = data => createFeedback(omit(data, ['comply']));
  const onCloseHandler = () => {
    onClose();
    if(result.isSuccess) {
      result.reset();
      reset();
    }
  }

  useEffect(() => {
    if (result.isSuccess) {
      trackUserSubmittedFeedback();
      reset();
    }
  }, [result.isSuccess]);

  useEffect(()=> {
  if(!isOpen) {
    result.reset();
  }
  }, [isOpen])
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      hasCloseButton
      CardSx={{
        minHeight: "auto",
      }}
      closeButtonLabel="Close Technical Support Pop up"
    >
      {!result.isSuccess && (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <ChatBubbleOutlineRoundedIcon sx={{ mr: 1 }} aria-hidden="true" role="img"/>
          <Typography variant="h2">
            {I18n.t('technicalSupport.title')}
          </Typography>
        </Box>
      )}

      {!result.isSuccess ? (
        <>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {I18n.t('technicalSupport.description')}
          </Typography>
          <Box
            component="form"
            sx={{
              mt: 3,
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { width: '100%' },
            }}
          >
            <TextField
              required
              autoFocus
              type="text"
              size="medium"
              error={!!errors.name}
              label={I18n.t('technicalSupport.yourName')}
              helperText={errors.name ? I18n.t('common.validation.helpText.required', { fieldLabel: I18n.t("technicalSupport.yourName") }) : ""}
              {...register('name', { required: true, minLength: 2 })}
            />
            <TextField
              required
              size="medium"
              type="email"
              error={!!errors.email}
              label={I18n.t('technicalSupport.email')}
              helperText={errors.email ? I18n.t('common.validation.helpText.required', { fieldLabel: I18n.t("technicalSupport.email") }) : ""}
              {...register('email', { required: true, validate: isEmail })}
            />
            <TextField
              required
              multiline
              minRows={4}
              size="medium"
              error={!!errors.message}
              helperText={errors.message ? I18n.t('technicalSupport.validation.messageLengthError') : ""}
              label={I18n.t('technicalSupport.message')}
              {...register('message', { required: true, minLength: 10 })}
            />

            <Controller
              control={control}
              name={'comply'}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={onChange} />}
                  label={I18n.t('technicalSupport.checkbox')}
                 />
                )}
            />
            {result.isError && <Alert severity="error" sx={{ whiteSpace: "pre-line" }}>{I18n.t('technicalSupport.errorSend')}</Alert>}
            <PrimaryButton
              type="submit"
              isLoading={result.status === QueryStatus.pending}
              disabled={
                !isNotAnEmergency ||
                !!errors.name ||
                !!errors.email ||
                !!errors.message ||
                !!errors.comply ||
                result.status === QueryStatus.pending
              }
              onClick={handleSubmit(onSubmitHandler)}
              label={I18n.t('technicalSupport.sendMessage')}
              sx={{ width: '100%', mt: 2 }}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            mb: !isMobileDownloadEnabled ? 5 : 0,
          }}
        >
          <Typography variant="h3">
            {I18n.t('technicalSupport.weReceivedMessage')}
          </Typography>

          <Typography variant="body1" sx={{ mt: 1 }}>
            {I18n.t('technicalSupport.thankYou')}
          </Typography>

          {isMobileDownloadEnabled && (
            <>
              <Box
                sx={{
                  top: 24,
                  zIndex: 1,
                  position: 'relative',
                  width: 'calc(100% + 48px)',
                  minHeight: 24,
                  boxShadow: 2,
                }}
              ></Box>

              <Box
                sx={{
                  top: 24,
                  position: 'relative',
                  width: 'calc(100% + 48px)',
                  backgroundColor: 'grey.50',
                  backgroundOpacity: 0.5,
                  minHeight: 100,
                  pb: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    display: ['none', 'none', 'flex'],
                    flex: 0.8,
                  }}
                >
                  <Box
                    component="img"
                    role="img"
                    src={Mockup}
                    alt=""
                    draggable={false}
                    sx={{
                      position: 'relative',
                      top: 24,
                      left: -18,
                      height: '220px',
                      width: 'auto',
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    gap: 2,
                    mt: [2, 2, 0],
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      lineHeight: 1.2,
                      textTransform: 'uppercase',
                      textAlign: ['center', 'center', 'left'],
                    }}
                  >
                    {I18n.t('marketing.feedbackModalTitle')}
                  </Typography>

                  <Typography variant="body2">
                    {I18n.t('marketing.feedbackModalDescription', { appName: I18n.t('common.appName') })}
                  </Typography>

                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 3,
                      position: 'relative',
                    }}
                  >
                    <AppleStoreIcon
                      sx={{
                        overflow: 'visible',
                        transform: 'scale(1.5)',
                        flexBasis: 120,
                      }}
                      viewBox="0 0 83 25"
                      width="83"
                      height="25"
                    />

                    <GoogleStoreIcon
                      sx={{
                        overflow: 'visible',
                        transform: 'scale(1.5)',
                        flexBasis: 120,
                      }}
                      viewBox="0 0 83 24"
                      width="83"
                      height="24"
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </Modal>
  );
};
