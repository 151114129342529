import { appMapConfig } from './../components/Map/config/map.config';
import { IUserLocation, LocationOrigin } from '../types';

const { latitude, longitude } = appMapConfig.initialViewState;

export const FallbackLocation: IUserLocation = {
  accuracyInMeters: null,
  coordinates: [latitude, longitude],
  origin: LocationOrigin.Fallback,
};

export const NotFound = 'NotFound';
export const InaccuracyInMetersForWarning = 50;
