export const wait = (msTime: number) =>
  new Promise(resolve => setTimeout(resolve, msTime));

enum UserOS {
  MacOS = 'MacOS',
  Windows = 'Windows',
  Linux = 'Linux',
  Android = 'Android',
  iOS = 'iOS',
  Unknown = 'Unknown',
}

export const getUserOS = (): keyof typeof UserOS => {
  const { userAgent, platform } = window.navigator;
  const lowerCasePlatform = platform.toLowerCase();
  const lowerCaseUserAgent = userAgent.toLowerCase();

  const macosPlatforms = [
    'macintosh',
    'macintel',
    'macppc',
    'mac68k',
    'mac',
    'darwin',
  ];
  const windowsPlatforms = ['win32', 'win64', 'windows', 'wince'];
  const iosPlatforms = ['iphone', 'ipad', 'ipod'];
  const androidPlatforms = ['android'];
  const linuxPlatforms = ['linux'];

  if (macosPlatforms.includes(lowerCasePlatform)) {
    return UserOS.MacOS;
  }

  if (iosPlatforms.includes(lowerCasePlatform)) {
    return UserOS.iOS;
  }

  if (windowsPlatforms.includes(lowerCasePlatform)) {
    return UserOS.Windows;
  }

  if (androidPlatforms.includes(lowerCaseUserAgent)) {
    return UserOS.Android;
  }

  if (linuxPlatforms.includes(lowerCasePlatform)) {
    return UserOS.Linux;
  }

  return UserOS.Unknown;
};
