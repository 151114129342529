import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18n-js';
import { capitalize } from 'lodash';
import { useContext } from 'react';

import { ReactComponent as FeedbackIcon } from '~/assets/icons/feedback.svg';
import { ReactComponent as PrivacyIcon } from '~/assets/icons/privacy.svg';
import { DownloadAppCTA } from '~/components/Marketing/DownloadAppCTA';
import RebrandingHeader from '~/components/Marketing/RebrandingHeader';
import {
  FaqUrl,
  GenasysHomeUrl,
  GenasysPrivacyPolicyUrl,
} from '~/constants/urls';
import { ModalsContext, ModalsContextIds } from '~/context';
import {
  isFeedbackButtonEnabled,
  isMobileDownloadEnabled,
  isRebrandingAnnounceEnabled,
} from '~/flags';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { Card } from '~/layouts/Card';
import { Page } from '~/layouts/Page';

const linkStyles: CSSProperties = {
  color: "#005D56", 
  textDecoration: 'underline',
 };
 
const About = () => {
  const isMobile = useIsMobileDevice();
  const theme = useTheme();
  const modalsContext = useContext(ModalsContext);
  // Whether or not our viewport is any smaller than large
  const isSmaller = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Page title={I18n.t('about.title')}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          rowSpacing={3}
          sx={{
            maxWidth: 1200,
            margin: '0 auto',
            width: '100%',
          }}
        >
          <Grid item md={12} lg={7}>
            <Card
              backgroundColor="grey"
              hasShadow={false}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'center',
                height: '100%',
                marginRight: isMobile ? 0 : 3,
                padding: isMobile
                  ? '49px 24px 24px 24px'
                  : '49px 60px 48px 60px',
              }}
            >
              {isRebrandingAnnounceEnabled && <RebrandingHeader />}
              <Typography
                variant="body1"
                sx={{
                  my: 3,
                  fontWeight: 700,
                }}
              >
                {isRebrandingAnnounceEnabled
                  ? I18n.t('rebranding.announcementIsNow', {
                      appName: I18n.t('common.appName'),
                    })
                  : I18n.t('about.disclaimer.title', {
                      appName: I18n.t('common.appName'),
                    })}
              </Typography>

              <Typography variant="body2">
                {I18n.t('about.disclaimer.paragraph1')}
                <a
                  href={GenasysHomeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkStyles}
                >
                  {I18n.t('about.disclaimer.link')}
                </a>
                {I18n.t('about.disclaimer.paragraph2')}
              </Typography>

              <Typography variant="body2" sx={{ mt: 2 }}>
                {I18n.t('about.disclaimer.paragraph3', {
                  appName: I18n.t('common.appName'),
                })}
              </Typography>
            </Card>
          </Grid>

          <Grid item md={12} lg={5} flex={1}>
            <Stack
              direction="column"
              spacing={3}
              sx={{ height: '100%', width: '100%' }}
            >
              <Card
                backgroundColor="grey"
                hasShadow={false}
                sx={{
                  flex: 1,
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                }}
              >
                <FeedbackIcon role="img" aria-hidden="true" />

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {I18n.t('about.learnMoreAbout', {
                    appName: t('common.appName'),
                  })}
                </Typography>

                <Typography variant="body2">
                  <a target="_blank" rel="noopener noreferrer" href={FaqUrl} style={{...linkStyles,fontSize:"16px"}}>
                    {I18n.t('about.visitFAQ')}
                  </a>
                </Typography>
              </Card>

              <Card
                backgroundColor="grey"
                hasShadow={false}
                sx={{
                  textAlign: 'center',
                  flex: 1,
                  gap: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PrivacyIcon role="img" aria-hidden="true" />

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {I18n.t('about.privacy')}
                </Typography>

                <Typography variant="body2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={GenasysPrivacyPolicyUrl}
                    style={linkStyles}
                  >
                    {GenasysPrivacyPolicyUrl}
                  </a>
                </Typography>
              </Card>
            </Stack>
          </Grid>

          {isMobileDownloadEnabled && (
            <Grid item xs={12}>
              <Card
                backgroundColor="grey"
                hasShadow={false}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <DownloadAppCTA />
              </Card>
            </Grid>
          )}
          <Grid item md={12}>
            <Card
              backgroundColor="grey"
              hasShadow={false}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                minHeight: '48px',
                p: 3,
                gap: 1,
              }}
            >
              <Typography variant="body1">
                {I18n.t('about.disclaimer.emergency')}
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: isSmaller ? 'static' : 'relative',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            width: '100%',
            my: 3,
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{ display: 'inherit', flexDirection: 'inherit' }}
            component="footer"
            role="contentinfo"
          >
            <Typography variant="caption">
              {isRebrandingAnnounceEnabled
                ? I18n.t('common.rebrandingMain', {
                    appName: I18n.t('common.appName'),
                  })
                : I18n.t('common.appName')}
            </Typography>
            <Typography variant="caption" sx={{ opacity: 0.5 }}>
              {process.env.NODE_ENV !== 'production'
                ? `${capitalize(process.env.NODE_ENV)} - `
                : ''}
              Version {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
          {isFeedbackButtonEnabled && (
            <Button
              variant={isSmaller ? 'contained' : 'text'}
              onClick={() =>
                modalsContext.setCurrentOpenedModal(ModalsContextIds.Feedback)
              }
              sx={{
                position: 'absolute',
                top: isSmaller ? 16 : 0,
                px: 2,
                right: isSmaller ? 16 : 90,
                zIndex: 5,
              }}
            >
              {I18n.t('about.needTechnicalHelp')}
            </Button>
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default About;
