import { Button, Card, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";

import { ReactComponent as AppStoreQRImage } from "~/assets/images/qrcodes/qrcode-mobile-app-appstore.svg";
import { ReactComponent as GoogleplayQRImage } from "~/assets/images/qrcodes/qrcode-mobile-app-googleplay.svg";
import { Modal } from "~/components/Modal/Modal";
import { I18n } from "~/i18n";

export type DownloadAppVariant = "appstore" | "googleplay";

type DownloadAppQRModalProps = {
  isOpen: boolean;
  variant: DownloadAppVariant;
  onClose: () => void;
}

export const DownloadAppQRModal = (props: DownloadAppQRModalProps) => {
  const theme = useTheme();

  const QRCodeImage = useMemo(()=> {
    if (props.variant === "appstore") {
      return AppStoreQRImage;
    }
    return GoogleplayQRImage;
  }, [props.variant]);

  return (
    <Modal
      isOpen={props.isOpen}
      CardSx={{
        gap: 3,
        px: 9,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        bgcolor: theme.palette.common.white,
        overflow:"auto",
        overflowX:"hidden"
      }}
    >
      <Typography variant="h1">{I18n.t("downloadApp.modal.title")}</Typography>
      <Card
        sx={{
          margin: "0 auto",
          width: 200,
          height: 200,
          p: 1,
          overflow:"visible"
        }}>
        <QRCodeImage />
      </Card>
      <Typography sx={{ mb: 2 }}>{I18n.t("downloadApp.modal.description")}</Typography>
      <Button
        variant="contained"
        onClick={props.onClose}
        sx={{ width: 343}}
      >{I18n.t("downloadApp.modal.buttonText")}</Button>
    </Modal>
  );
};


