import * as turf from '@turf/turf';
import { Buffer } from 'buffer';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Position } from 'geojson';
import { LngLat, LngLatBounds, MapGeoJSONFeature } from 'maplibre-gl';
import { MapRef } from 'react-map-gl/maplibre';
import { getPointOfInterestType } from '~/components/Map/config';
import { ArrivalPoint, RoadClosure } from '~/components/Map/types';

const geoserverCreds = JSON.parse(process.env.REACT_APP_GEOSERVER_CREDS);

const geoserver = {
  WMTS: process.env.REACT_APP_GEOSERVER_WMTS ?? '',
  WFS: process.env.REACT_APP_GEOSERVER_WFS ?? '',
  Creds: btoa(geoserverCreds.username + ':' + geoserverCreds.password) ?? '',
};

const maplibre = {
  StreetUrl:
    `${process.env.REACT_APP_ESRI_MAP_URL}${process.env.REACT_APP_ESRI_APIKEY}` ??
    '',
};

const getWMTS = (
  layer: string,
  gridSet = 'EPSG:900913',
  tileMatrix = gridSet,
): string => {
  return (
    `${geoserver.WMTS}` +
    '?REQUEST=GetTile&SERVICE=WMTS' +
    '&VERSION=1.0.0&LAYER=' +
    'zonehavenv2' +
    ':' +
    layer +
    `&STYLE=&TILEMATRIX=${tileMatrix}:{z}` +
    `&TILEMATRIXSET=${gridSet}&FORMAT=application/vnd.mapbox-vector-tile` +
    '&TILECOL={x}&TILEROW={y}&caheVersion=' +
    Math.round(new Date().getTime() / 1000)
  );
};

const getLatLngCenterFromBbox = bbox => {
  const getSwAndNeFromBbox = bbox => {
    const parenthesesContentRegEx = /\(([^)]+)\)/;
    const coords = bbox.match(parenthesesContentRegEx)[1];
    const [coords1, coords2] = coords.split(',');
    const [lat1, lng1] = coords1.split(' ');
    const lngLatSW = new LngLat(lat1, lng1);
    const [lat2, lng2] = coords2.split(' ');
    const lngLatNE = new LngLat(lat2, lng2);
    return { sw: lngLatSW, ne: lngLatNE };
  };

  const { sw, ne } = getSwAndNeFromBbox(bbox);
  const bounds = new LngLatBounds(sw, ne);
  const center = bounds.getCenter();
  return [center.lat, center.lng] as Position;
};

const getZoneIdFromZoneName = (id: string) => {
  if (!id) {
    return '';
  }
  const splitted = id.split('-');
  return splitted.slice(3).join('-').trim();
};

const getZoneCenter = (zoneId: number, map: MapRef) => {
  const feature = map.queryRenderedFeatures(null, {
    layers: ['evacuation_zone'],
    filter: ['==', 'zone_id', zoneId],
  })[0];

  if (!feature) {
    return null;
  }

  return turf.centerOfMass(feature).geometry.coordinates.reverse() as Position;
};

const getApOrTcpFromMapData = (
  mapData: MapGeoJSONFeature,
  prevData: { id: string },
  sourceLayer?: string,
): RoadClosure | ArrivalPoint => ({
  ...prevData,
  type: getPointOfInterestType(mapData as MapGeoJSONFeature, sourceLayer),
  address:
    mapData.properties.tcp_name ?? mapData.properties.arrival_point_address,
  name: mapData.properties.arrival_point_name,
  description: mapData.properties.tcp_note
    ? stateToHTML(convertFromRaw(JSON.parse(mapData.properties.tcp_note)))
    : '',
});

export {
  geoserver,
  maplibre,
  getWMTS,
  getLatLngCenterFromBbox,
  getZoneIdFromZoneName,
  getZoneCenter,
  getApOrTcpFromMapData,
};
