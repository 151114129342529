import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

import {
  AlphaFirebaseConfig,
  BetaFirebaseConfig,
  DemoFirebaseConfig,
  ProdFirebaseConfig,
} from "constants/firebase";
import { Envs } from "types";

export const initializeFirebase = (config) => {
  const FirebaseApp = initializeApp(config);
  const Analytics = getAnalytics(FirebaseApp);

  return { FirebaseApp, Analytics };
};

export const getFirebaseEnvironmentConfig = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  if (env === Envs.ALPHA || env === Envs.DEV) {
    return AlphaFirebaseConfig;
  }

  if (env === Envs.DEMO) {
    return DemoFirebaseConfig;
  }

  if (env === Envs.BETA) {
    return BetaFirebaseConfig;
  }

  if (env === Envs.PROD) {
    return ProdFirebaseConfig;
  }

  // Fallback - TODO: Catch error
  return AlphaFirebaseConfig;
};
