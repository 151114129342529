import { createContext, useState } from "react";

import { FollowZoneExtraDataType, SearchZoneExtraDataType } from "~/components/Map/types";

export enum ModalsContextIds {
  Onboarding = "onboarding",
  GpsPermission = "gpsPermission",
  Feedback = "feedback",
  FollowZone = "followZone",
  DownloadApp = "downloadApp",
  SplitSearch = "splitSearch",
  MergedSearch = "mergedSearch"
}

export const ModalsContext = createContext<{
  currentOpenedModal: ModalsContextIds | null;
  extraData: FollowZoneExtraDataType | SearchZoneExtraDataType | null;
  setCurrentOpenedModal: (
    id: ModalsContextIds | null,
    extraData?: FollowZoneExtraDataType | SearchZoneExtraDataType | null
  ) => void;
  closeModals: () => void;
}>({
  currentOpenedModal: null,
  extraData: null,
  setCurrentOpenedModal: () => void null,
  closeModals: () => void null,
});

export const ModalsContextContainer = ({ children }) => {
  const [currentOpenedModal, setCurrentOpenedModal] =
    useState<ModalsContextIds | null>(null);
  const [extraData, setExtraData] = useState<FollowZoneExtraDataType | SearchZoneExtraDataType | null>(
    null
  );

  const value = {
    extraData,
    currentOpenedModal,
    setCurrentOpenedModal: (
      id: ModalsContextIds | null,
      extraData?: FollowZoneExtraDataType | SearchZoneExtraDataType | null
    ) => {
      setCurrentOpenedModal(id);
      setExtraData(extraData ?? null);
    },
    closeModals: () => {
      setExtraData(null);
      setCurrentOpenedModal(null);
    },
  };

  return (
    <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
  );
};
