/* eslint-disable complexity */
import { MouseEvent, ReactElement } from "react";

import iconMap from "./assets/icon-map";
import { StyledIconContainer } from "./styles";

interface IconProps {
  borderRadius?: number;
  /**
   * Type
   */
  name: string;
  /**
   * Size
   */
  size?: number;
  /**
   * What color to use
   */
  color?: string;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * What background shape to use
   */
  backgroundShape?: "none" | "circle" | "rectangle";
  /**
   * Add additional class names
   */
  className?: string;
  /**
   * Add additional alt/title text
   */
  title?: string;
  /**
   * Is this a draggable element?
   */
  isDraggable?: boolean;
  /**
   * Optional click handler
   */
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * alternate text 
   */
  alt?: string;
}

/* istanbul ignore next */
const EmptyIcon = () => <div />;

/**
 * Icon component
 */
const Icon = ({
  name,
  backgroundShape = "none",
  size = 15,
  color = "white",
  backgroundColor = "#0E4E9A",
  className,
  title = "",
  isDraggable = false,
  borderRadius = 0,
  onClick,
  alt="",
  ...props
}: IconProps): ReactElement => {
  const Icon = iconMap[`${name}`] ?? EmptyIcon;
  const styleSize = { width: size, height: size, color };
  const iconClass = "sb-icon";

  if (backgroundShape === "none") {
    return (
      <StyledIconContainer
        onClick={onClick}
        draggable={isDraggable}
        className="sb-icon-div"
      >
        <Icon
          style={styleSize}
          className={[iconClass, className].join(" ")}
          title={title}
          {...props}
          role="img"
          alt={title ?? alt}
        />
      </StyledIconContainer>
    );
  }
  return (
    <div
      className="sb-icon-div"
      onClick={onClick}
      draggable={isDraggable}
      style={{ display: "inline-flex" }}
    >
      <svg
        color={backgroundColor}
        width={size}
        height={size}
        viewBox={"0 0 30 30"}
        xmlns="http://www.w3.org/2000/svg"
        className={[iconClass, className].join(" ")}
        role="img"
        style={{ overflow: "hidden", borderRadius }}
        aria-label={title ?? alt}
      >
        {title ? <title>{title}</title> : null}
        {backgroundShape === "circle" ? (
          <circle
            cx="15"
            cy="15"
            r="15"
            fill={"currentColor"}
            data-testid={"iconShape"}
          />
        ) : (
          <rect
            x="0"
            y="0"
            width={"30"}
            height={"30"}
            fill={"currentColor"}
            data-testid={"iconShape"}
          />
        )}
        <g transform="translate(8 7) scale(0.6)" fill={"currentColor"}>
          <Icon style={styleSize} {...props} />
        </g>
      </svg>
    </div>
  );
};

export default Icon;
