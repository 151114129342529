import { Box } from '@mui/material';
import { isRebrandingAnnounceEnabled } from 'flags';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import useVH from 'react-viewport-height';
import { updateUserLocationThunk, useAppDispatch } from 'redux-rtk';
import { LocalStorageKeys, LocationOrigin } from 'types';
import { useLocalStorage } from 'usehooks-ts';
import { Map } from '~/components/Map';
import {
  FollowZoneExtraDataType,
  SearchZoneExtraDataType,
} from '~/components/Map/types';
import { DownloadAppModal } from '~/components/Marketing/DownloadAppModal';
import { RebrandingBanner } from '~/components/Marketing/RebrandingBanner';
import { FeedbackModal } from '~/components/Modals/FeedbackModal';
import { FollowZoneModal } from '~/components/Modals/FollowZoneModal';
import { GPSInstructionsModal } from '~/components/Modals/GPSInstructionsModal';
import { MergeSearchModal } from '~/components/Modals/MergeSearchModal';
import { OnboardingModal } from '~/components/Modals/OnboardingModal';
import { SplitSearchModal } from '~/components/Modals/SplitSearchModal';
import { Navbar } from '~/components/Navbar/Navbar';
import { NoInternetConnection } from '~/components/NoInternetConnection';
import { ModalsContext, ModalsContextIds } from '~/context/ModalsContext';
import { useIsMobileDevice } from '~/hooks';
import { Router } from '~/router/Router';
import Setup from '~/setup/Setup';

const App = () => {
  useVH();

  const currentPage = useLocation().pathname;

  const [preferredOrigin, setPrefferedOrigin] =
    useLocalStorage<LocationOrigin | null>(
      LocalStorageKeys.PreferredOrigin,
      null,
    );

  const [cookie, _, removeCookie] = useCookies();
  const isMobile = useIsMobileDevice('lg');

  const dispatch = useAppDispatch();

  const isFullscreen = ['/about', '/download', '/fullscreenMap'].some(
    p => p === currentPage,
  );

  const deprecatedPreferredOrigin =
    cookie?.zh_settings?.preferredLocationOrigin;

  // this hook is only used to sync prev settings w new localStorage approach
  useEffect(() => {
    if (deprecatedPreferredOrigin && !preferredOrigin) {
      setPrefferedOrigin(deprecatedPreferredOrigin);
      removeCookie('zh_settings');
    }
  }, [deprecatedPreferredOrigin, preferredOrigin]);

  useEffect(() => {
    if (preferredOrigin) {
      dispatch(updateUserLocationThunk(preferredOrigin));
    }
  }, [preferredOrigin]);

  return (
    <Setup>
      {isRebrandingAnnounceEnabled ? <RebrandingBanner /> : null}

      <NoInternetConnection />

      <Box
        className="app"
        sx={theme => ({
          width: '100vw',
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          minHeight: 0,
          alignItems: 'flex-end',
          '& > *': {
            height: '100%',
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <Navbar />
        <Box
          component="main"
          sx={{ display: 'inherit', flex: 1, overflow: 'hidden' }}
        >
          <Box
            sx={theme => ({
              zIndex: 4,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'flex-end',
              position: 'relative',
              width: isMobile || isFullscreen ? '100%' : '450px',
              [theme.breakpoints.down('md')]: {
                width: '100vw',
                height: '100%',
              },
            })}
            component="section"
            aria-label="Page"
          >
            <Router />
          </Box>

          {!isMobile && !isFullscreen && (
            <Box
              sx={{ display: 'flex', flex: 1, height: '100vh', alignSelf: 'flex-end' }}
              component="section"
              aria-label="Map"
            >
              <Map />
            </Box>
          )}
        </Box>

        <ModalsContext.Consumer>
          {({ currentOpenedModal, closeModals, extraData }) => (
            <>
              <FeedbackModal
                isOpen={currentOpenedModal === ModalsContextIds.Feedback}
                onClose={closeModals}
              />

              <OnboardingModal />

              <GPSInstructionsModal
                onClose={closeModals}
                isOpen={currentOpenedModal === ModalsContextIds.GpsPermission}
              />

              <FollowZoneModal
                onClose={closeModals}
                zoneId={(extraData as FollowZoneExtraDataType)?.zoneId}
                isOpen={currentOpenedModal === ModalsContextIds.FollowZone}
              />

              <SplitSearchModal
                onClose={closeModals}
                zones={(extraData as SearchZoneExtraDataType)?.zones || []}
                onZoneChosen={
                  (extraData as SearchZoneExtraDataType)?.onZoneChosen
                }
                isOpen={currentOpenedModal === ModalsContextIds.SplitSearch}
              />

              <MergeSearchModal
                onClose={closeModals}
                zones={(extraData as SearchZoneExtraDataType)?.zones || []}
                onZoneChosen={
                  (extraData as SearchZoneExtraDataType)?.onZoneChosen
                }
                isOpen={currentOpenedModal === ModalsContextIds.MergedSearch}
              />

              <DownloadAppModal
                onClose={closeModals}
                isOpen={currentOpenedModal === ModalsContextIds.DownloadApp}
              />
            </>
          )}
        </ModalsContext.Consumer>
      </Box>
    </Setup>
  );
};

export default App;
