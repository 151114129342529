import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// This is the base API handler for zonehaven related calls.
// Put any fetch calls to this endpoint in a separate file if needed.
// E.g. api calls for zones in in zoneApi.ts. Make new files for each theme of api calls as needed.
// This file should only be used for configuration (i.e. setting headers or keys)
export const BaseZonehavenApi = createApi({
  reducerPath: "zonehavenApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  }),
  endpoints: () => ({}),
});
