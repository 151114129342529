import { URLSearchParams } from "url";

export const paramsToObject = (params: URLSearchParams) => {
  const result = {};
  for (const [key, value] of params) {
    // eslint-disable-next-line security/detect-object-injection
    result[key] = value;
  }
  return result;
};
