import { Box, Typography } from '@mui/material';

import { useIsMobileDevice } from 'hooks';
import { I18n } from 'i18n';
import { PrimaryButton } from '~/components/common/Buttons';
import { Modal } from '~/components/Modal';

import { ReactComponent as HouseIcon } from '../../assets/icons/house.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile-app.svg';
import Mockup from '../../assets/images/iphone-mockup.png';
import { ReactComponent as AppLogo } from '../../assets/images/logo/logo_protect_dark_text_vertical.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DesktopModalWidth = 900;
const DesktopModalHeight = 500;
const BlueCircleAdditionalWidth = 200;

export const DownloadAppModal = ({ isOpen, onClose }: Props) => {
  const isMobile = useIsMobileDevice();
  return (
    <Modal
      hasCloseButton
      isOpen={isOpen}
      onClose={onClose}
      CardSx={{
        padding: [3, 3, 4],
        maxWidth: 'unset',
        overflow: 'hidden',
      }}
      CloseButtonSx={{
        zIndex: 1,
      }}
      closeIconColor={!isMobile ? 'white' : undefined}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          userSelect: 'none',
          gap: [0, 0, 3],
          width: ['100%', '100%', `${DesktopModalWidth}px`],
          flexDirection: 'row',
        }}
      >
        {/* Left content */}
        <Box
          sx={{
            width: ['100%', '100%', '50%'],
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <AppLogo height="50" width="270" data-testid="app-logo" title={`${I18n.t("common.appName")} Logo`} />

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="body1"
              sx={{ color: 'grey.600', textTransform: 'uppercase' }}
            >
              {I18n.t('marketing.youAskedWeListened')}
            </Typography>

            <Typography
              variant={isMobile ? 'h2' : 'h1'}
              sx={{
                lineHeight: 1.25,
                mt: 0.5,
                textTransform: 'unset',
              }}
            >
              {I18n.t('marketing.modalDescription')}
            </Typography>
            <Typography
              variant={isMobile ? 'h2' : 'h1'}
              sx={{
                lineHeight: 1.25,
                color: 'primary.light',
              }}
            >
              {I18n.t('common.appName')}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: 2,
              justifyContent: 'space-around',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '50%',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MobileIcon />

              <Typography variant="body1" sx={{ mt: 0.5 }}>
                {I18n.t('marketing.newUserExperience')}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '50%',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <HouseIcon />

              <Typography variant="body1" sx={{ mt: 0.5 }}>
                {I18n.t('marketing.registerAndFollowZone')}
              </Typography>
            </Box>
          </Box>

          <PrimaryButton
            label={I18n.t('marketing.downloadAppNow')}
            onClick={() => {
              window.open(
                'https://apps.apple.com/us/app/aware/id1560000000',
                '_blank',
              );
            }}
            sx={{
              bgcolor: 'primary.light',
              width: '100%',
            }}
          />
        </Box>

        {/* Right content */}
        <Box
          sx={{
            width: '50%',
            position: 'relative',
            display: ['none', 'none', 'block'],
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -(BlueCircleAdditionalWidth / 2),
              width: `${DesktopModalHeight + BlueCircleAdditionalWidth}px`,
              height: `${DesktopModalHeight + BlueCircleAdditionalWidth}px`,
              bgcolor: 'secondary.light',
              borderRadius:
                (DesktopModalHeight + BlueCircleAdditionalWidth) / 2,
            }}
          />

          <Box
            component="img"
            src={Mockup}
            alt="logo"
            draggable={false}
            sx={{
              height: DesktopModalHeight / 1.15,
              width: 'auto',
              zIndex: 100,
              position: 'absolute',
              top: DesktopModalHeight * 0.075,
              right: -32,
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
