import { Fragment, PropsWithChildren } from 'react';

import { useAnalytics } from '~/analytics/hooks';

import { getFirebaseEnvironmentConfig, initializeFirebase } from './firebase';

// Initialize Firebase
const FirebaseEnvConfig = getFirebaseEnvironmentConfig();
export const FirebaseApp = initializeFirebase(FirebaseEnvConfig);

const Setup = ({ children }: PropsWithChildren<unknown>) => {
  useAnalytics();

  return <Fragment>{children}</Fragment>;
};

export default Setup;
