import { Close } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { PropsWithChildren } from 'react';

import { I18n } from 'i18n';
import { Loader } from '~/components/Loader';
import { MotionDiv } from '~/components/MotionDiv';
import { Card } from '~/layouts/Card';
import { PropsWithSx } from '~/utils';

interface Props {
  title?: string;
  minHeight?: number;
  isFetching?: boolean;
  checkedFollowButton?: boolean;
  isFollowButtonVisible?: boolean;
  closeButtonLabel: string;

  onClose?: () => void;
  onFollowChange?: (boolean) => void;
}

export const AsyncMapCard = ({
  sx,
  title,
  onClose,
  children,
  minHeight = 150,
  isFetching,
  checkedFollowButton = false,
  onFollowChange,
  closeButtonLabel,
  isFollowButtonVisible = false,
}: PropsWithSx<PropsWithChildren<Props>>) => {
  return (
    <Card
      sx={{
        minWidth: 300,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 2,
          right: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {isFollowButtonVisible ? (
          <FormControlLabel
            value="start"
            componentsProps={{
              typography: {
                variant: 'body2',
                color: 'text.secondary',
              },
            }}
            control={
              <Switch
                sx={{ mr: 1 }}
                color="primary"
                checked={checkedFollowButton}
                onChange={(e, checked) => onFollowChange(checked)}
                inputProps={{ 'aria-label': 'Follow or unfollow zone' }}
              />
            }
            labelPlacement="start"
            label={
              !checkedFollowButton
                ? I18n.t('zoneDetails.followZone')
                : I18n.t('zoneDetails.followingZone')
            }
          />
        ) : null}
        {/* Render the close button if it not in the process of fetching data for zone details. and
            we have a valid onClose */}
        {!isFetching && typeof onClose === 'function' && (
          <IconButton
            onClick={onClose}
            sx={{ marginLeft: 0 }}
            title={closeButtonLabel}
          >
            <Close
              color="secondary"
              role="img"
              titleAccess={closeButtonLabel}
            />
          </IconButton>
        )}
      </Box>

      <AnimatePresence exitBeforeEnter>
        <MotionDiv key={`${isFetching}`}>
          {isFetching ? (
            <Box
              sx={{
                minHeight,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader height={25} width={100} />
            </Box>
          ) : (
            <>
              {title && (
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {title}
                </Typography>
              )}
              {children}
            </>
          )}
        </MotionDiv>
      </AnimatePresence>
    </Card>
  );
};
