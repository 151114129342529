import { validate as uuidValidate } from 'uuid';
import { ZoneDetails } from '~/components/Map/types';

import { NotFound, ZoneRegex } from '../constants';

// eslint-disable-next-line security/detect-unsafe-regex
const parseZoneIdentifierRegex = /^([^-]*)-([^-]*)-(?:[^-]*-)(.*)$/;

export const parseZoneIdentifier = (zoneId): string[] => {
  // The first index is the entire matched object, but we only want the three matched groups
  return parseZoneIdentifierRegex.exec(zoneId).slice(1);
};

export const isZoneId = (zoneId: string): boolean => {
  return ZoneRegex.test(zoneId);
};

export const isHazardId = (hazardId: string): boolean => {
  return uuidValidate(hazardId);
};

export const getZoneIdentifierFromSearchResult = (
  zoneDetails: ZoneDetails,
  selectedZoneId: string,
) => {
  // The logic for figuring out which zone identifier to put into the url is a bit complicated:
  // * If our result had a zone identifier, just use it
  // * Otherwise, if it was a "merged" historical result, use the first child (since there
  //   should only be one), and we search the list of `parentZones` for the one that ends with
  //   the search term we used. This is the same logic we use for the initial searching of zone
  //   identifiers, and it gives us the "full" identifier instead of potentially a shortened one
  // * Otherwise, if it was a "split" historical result, navigate to the searched-for zone
  //   identifier; it will be detected as a split & handle it from there
  // * Otherwise return "NotFound"
  if (zoneDetails.zone?.identifier) {
    return zoneDetails.zone?.identifier;
  }
  if (zoneDetails.history?.type === 'merged') {
    return (
      zoneDetails.history.children[0].parentZones.find(parentZoneIdentifier =>
        parentZoneIdentifier.endsWith(selectedZoneId.toUpperCase()),
      ) ?? NotFound
    );
  }
  if (zoneDetails.history?.type === 'split') {
    return selectedZoneId.toUpperCase();
  }
  return NotFound;
};
