import { Color } from '../../../constants';
import { Layer } from 'react-map-gl/maplibre';

interface HighlightProps {
  zoneId: string;
  color?: string;
}

export const LayerHighlightZone = ({ zoneId, color }: HighlightProps) => {
  if (!zoneId) {
    return null;
  }

  return (
    <Layer
      id="zone-highlighted-fill"
      type="fill"
      source="evacuation_zone"
      source-layer="evacuation_zone"
      paint={{
        'fill-outline-color': color ?? Color.PrimaryGreen,
        'fill-color': color ?? Color.PrimaryGreen,
        'fill-opacity': 0.3,
      }}
      filter={['==', 'identifer', zoneId]}
    />
  );
};

interface LineProps {
  zoneId: string;
}

export const LayerHighlightLine = ({ zoneId }: LineProps) => {
  if (!zoneId) {
    return null;
  }

  return (
    <Layer
      id="zone-highlighted-line"
      type="line"
      source="evacuation_zone"
      source-layer="evacuation_zone"
      paint={{
        'line-opacity': 0.7,
        'line-width': 4.5,
        'line-color': Color.LeafGreen,
      }}
      filter={['==', 'identifer', zoneId]}
    />
  );
};
