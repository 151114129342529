import { Box, Button, Typography } from '@mui/material';

import { PrimaryButton } from '~/components/common/Buttons';
import { Zone } from '~/components/Map/types';
import { Modal } from '~/components/Modal/Modal';
import { I18n } from '~/i18n';


interface SplitSearchProps {
  isOpen: boolean;
  zones: Zone[]; 
  onZoneChosen : (chosenZone: Zone) => void;
  onClose: () => void;
}

export const SplitSearchModal = ({
  isOpen,
  zones,
  onClose,
  onZoneChosen,
}: SplitSearchProps) => {


  return (
    <Modal
      isOpen={isOpen}
      hasCloseButton
      onClose={onClose}
      CardSx={{
        flexGrow: 1,
        mx: [3, 3, 'auto'],
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mr: 4, gap: 2 }}>
        <Typography variant="h2">{I18n.t('search.selectSplitZonesTitle')}</Typography>
        <Typography>{I18n.t('search.selectSplitZonesMessage')}</Typography>
      </Box>

      <Box
        sx={{
          mt: 2,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        {
          zones.map((zone: Zone, index: number) => { 
            return (
            <PrimaryButton
              key={index}
              label={zone.identifier}
              onClick={() => {
                onZoneChosen(zone);
                onClose();
              }}
            />
            )
          })
        }
        <Button
          variant="outlined"
          onClick={onClose} 
          sx={{
            fontSize: 16,
            height: "40px",
            borderRadius: "50px",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: 1,
              transform: "scale(1.01)",
            },
          }}
        >
          {I18n.t('search.cancelButtonText')}
        </Button>
      </Box>
    </Modal>
  );
};
