import { useFetchWeatherQuery } from '../../../redux-rtk';
import { PropsWithSx } from '../../../utils';
import {
  getIconNameFromWeatherCode,
  getWeatherDescriptionFromWeatherCode,
} from '../../../utils/weatherUtils';
import { Loader } from '../../UI';
import { Icon } from '../../UI/Icon';
import { Box, Typography } from '@mui/material';
import { Position } from 'geojson';

interface ZoneWeatherProps {
  location: Position;
}

export const ZoneWeather = ({
  location,
  sx,
}: PropsWithSx<ZoneWeatherProps>) => {
  const { data, isLoading } = useFetchWeatherQuery(location, {
    refetchOnMountOrArgChange: true,
  });
  const weatherData = data?.intervals[0].values;

  if (!weatherData && !isLoading) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              height: 48,
              width: 48,
              position: 'relative',
              left: 12,
              top: 12,
            }}
          >
            <Icon
              size={24}
              name={getIconNameFromWeatherCode(weatherData.weatherCode)}
              isDecorative={true}
            />
          </Box>
          <Box
            sx={{
              ml: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              {weatherData.temperature}°F
            </Typography>
            <Typography variant="body2">
              {getWeatherDescriptionFromWeatherCode(weatherData.weatherCode)}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
