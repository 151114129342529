import Lottie, { Options } from "react-lottie";

interface LoadingProps {
  variant?: 1 | 2 | 3;
  width?: number;
}

export const OnBoardingAnimation = ({
  width = 300,
  variant = 1,
}: LoadingProps) => {
  const defaultOptions: Options = {
    loop: false,
    autoplay: true,
    // eslint-disable-next-line security/detect-non-literal-require
    animationData: require(`../../assets/lotties/onboarding-${variant}.json`),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid",
    },
  };

  return <Lottie style={{overflow: "visible"}} width={width} options={defaultOptions} ariaRole="img" ariaLabel=""/>;
};
