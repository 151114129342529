import './index.css';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  ToastAutoHideDuration,
  ToastMaxConcurrentNotifications,
} from '~/constants';
import { ModalsContextContainer } from '~/context';
import { OnlineStatusProvider } from '~/hooks';
import App from '~/pages/App';
import { store } from '~/redux-rtk/store';
import { theme } from '~/theme/createTheme';

import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <OnlineStatusProvider>
          <CookiesProvider>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider
                maxSnack={ToastMaxConcurrentNotifications}
                autoHideDuration={ToastAutoHideDuration}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ModalsContextContainer>
                  <App />
                </ModalsContextContainer>
              </SnackbarProvider>
            </MuiThemeProvider>
          </CookiesProvider>
        </OnlineStatusProvider>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
