import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { Hazard, Zone } from "../components/Map/types";

export function isZone(zone?: Zone | Hazard): zone is Zone {
  return (
    (zone as Zone)?.id !== undefined && (zone as Zone)?.identifier !== undefined
  );
}

export function isHazard(hazard?: Zone | Hazard): hazard is Hazard {
  return (
    (hazard as Hazard)?.id !== undefined &&
    (hazard as Hazard)?.acreage !== undefined &&
    (hazard as Hazard)?.percentContained !== undefined
  );
}

export function isFetchBaseQueryError(
  error: FetchBaseQueryError | SerializedError
): error is FetchBaseQueryError {
  return error && Object.hasOwn(error, "status");
}
