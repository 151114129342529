/* eslint-disable security/detect-object-injection */
/* eslint-disable complexity */

// Reference: https://docs.tomorrow.io/reference/data-layers-weather-codes
export const getIconNameFromWeatherCode = (weatherCode: number) => {
  const asNumber = Number(weatherCode);
  switch (asNumber) {
    case 0:
      return "";
    case 1000:
    case 1100:
      return "clear";
    case 1101:
    case 1102:
      return "partly-cloudy";
    case 1001:
      return "cloudy";
    case 2000:
    case 2100:
      return "fog";
    case 4000:
      return "drizzle";
    case 4001:
    case 4200:
    case 4201:
      return "rain";
    case 5000:
    case 5001:
    case 5100:
    case 5101:
      return "snow";
    case 6000:
    case 6001:
    case 6200:
    case 6201:
    case 7000:
    case 7101:
    case 7102:
      return "snow-drizzle";
    case 8000:
      return "thunderstorm";
    default:
      return "";
  }
};

export const getWeatherDescriptionFromWeatherCode = (weatherCode: number) => {
  const mapCodesToDescription = {
    "0": "Unknown",
    "1000": "Clear, Sunny",
    "1100": "Mostly Clear",
    "1101": "Partly Cloudy",
    "1102": "Mostly Cloudy",
    "1001": "Cloudy",
    "2000": "Fog",
    "2100": "Light Fog",
    "4000": "Drizzle",
    "4001": "Rain",
    "4200": "Light Rain",
    "4201": "Heavy Rain",
    "5000": "Snow",
    "5001": "Flurries",
    "5100": "Light Snow",
    "5101": "Heavy Snow",
    "6000": "Freezing Drizzle",
    "6001": "Freezing Rain",
    "6200": "Light Freezing Rain",
    "6201": "Heavy Freezing Rain",
    "7000": "Ice Pellets",
    "7101": "Heavy Ice Pellets",
    "7102": "Light Ice Pellets",
    "8000": "Thunderstorm",
  };

  const asString = String(weatherCode);
  return mapCodesToDescription[asString] || "Unknown";
};
