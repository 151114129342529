import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SxProps, Dialog } from '@mui/material';
import { Card, CardProps } from '../../layouts/Card';
import { PropsWithChildren } from 'react';

export interface ModalProps {
  isOpen: boolean;
  CardSx?: SxProps;
  onClose?: () => void;
  CardProps?: CardProps;
  hasCloseButton?: boolean;
  CloseButtonSx?: SxProps;
  closeIconColor?: string;
  closeButtonLabel?: string;
}

export const Modal = ({
  isOpen,
  CardSx,
  onClose,
  children,
  CardProps,
  CloseButtonSx,
  hasCloseButton = false,
  closeIconColor = 'secondary',
  closeButtonLabel = null,
}: PropsWithChildren<ModalProps>) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        padding={3}
        backgroundColor={'white'}
        sx={{
          mx: 3,
          gap: 1,
          maxWidth: 510,
          borderRadius: 3,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          minWidth: [150, 150, 400],
          minHeight: 150,
          ...CardSx,
        }}
        {...CardProps}
      >
        {hasCloseButton && (
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 10, right: 10, ...CloseButtonSx }}
            title={closeButtonLabel}
          >
            <CloseIcon
              sx={{ color: closeIconColor }}
              role="img"
              titleAccess={closeButtonLabel}
            />
          </IconButton>
        )}
        {children}
      </Card>
    </Dialog>
  );
};