export enum MarkersNames {
  Fire = 'fire',
  OnRamp = 'onRamp',
  Shelter = 'shelter',
  RallyPoint = 'rallyPoint',
  RoadClosure = 'roadClosure',
  UserLocation = 'userLocation',
  AnimalShelter = 'animalShelter',
  ResourceCenter = 'resourceCenter',
  EvacuationCenter = 'evacuationCenter',
  TrafficControlPoint = 'trafficControlPoint',
}
