import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PropsWithSx } from "../../../../utils";
import { TextButton } from "../../../common/Buttons";
import { StateOrCurrentLocationChip } from "../../../StateOrCurrentLocationChip";
import { Zone } from "../../types";

interface ZoneFooterProps {
  zone: Zone;
  isUserZone: boolean;
  showMoreDetailsLink?: boolean;
}

export const ZoneFooter = ({
  sx,
  zone,
  isUserZone,
  showMoreDetailsLink = false,
}: PropsWithSx<ZoneFooterProps>) => {
  const navigate = useNavigate();

  const [, state] = zone.identifier.split("-");

  const handleDetailsClick = () => {
    navigate(`/zones/${zone.identifier}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <StateOrCurrentLocationChip
        stateAbbreviation={state}
        isUserZone={isUserZone}
      />

      {showMoreDetailsLink && <TextButton onClick={handleDetailsClick} />}
    </Box>
  );
};
