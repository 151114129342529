export const layersConfig = {
  minZoomLevel: {
    evacuationZone: 7,
    evacuationZoneId: 10,
    countyName: 7,
    countyBoundary: 7,
    countySeal: 7,
    fire: 7,
  },
  maxZoomLevel: {
    evacuationZone: 23,
    evacuationZoneId: 23,
    countyName: 23,
    countyBoundary: 23,
    fire: 23,
  },
};
