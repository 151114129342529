import {
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";

import { PropsWithSx } from "../../../utils";

interface PrimaryButtonProps extends ButtonProps {
  label: string;
  isLoading?: boolean;
  onClick: () => void;
}

export const PrimaryButton = ({
  sx,
  size,
  onClick,
  isLoading,
  label = "Primary button",
  ...props
}: PropsWithSx<PrimaryButtonProps>) => {
  const theme = useTheme();
  return (
    <Button
      size={size}
      onClick={onClick}
      variant="contained"
      sx={{
        height: "40px",
        borderRadius: "50px",
        transition: "all 0.3s ease-in-out",
        "&:hover, &:focus": {
          boxShadow: 1,
          transform: "scale(1.01)",
          backgroundColor: theme.palette.primary.dark
        },
        ...sx,
      }}
      {...props}
    >
      <Typography
        variant={size === "small" ? "body2" : "body1"}
        color={"common.white"}
        style={{
          // Remove <Typography> element from normal document flow and allow {label} to be rendered as direct child of <Button> 
          display: "contents", 
        }}
      >
        {label}
      </Typography>

      {isLoading && (
        <CircularProgress
          size={size === "small" ? 14 : 18}
          sx={{ ml: 2, color: "common.white" }}
        />
      )}
    </Button>
  );
};
