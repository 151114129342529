export type TileTypes =
  | 'vector'
  | 'canvas'
  | 'video'
  | 'image'
  | 'geojson'
  | 'raster'
  | 'raster-dem';

type GridSet =
  | 'EPSG:900913v2'
  | 'EPSG:900913'
  | 'EPSG:900913x2'
  | 'EPSG:4326'
  | 'EPSG:4326v2'
  | 'EPSG:4326x2';

export interface Tile {
  name: string;
  type: any | TileTypes;
  gridSet?: GridSet;
  tileMatrix?: GridSet;
  minzoom?: number;
  maxzoom?: number;
}

// Order of tiles needs to match layers in initialLayers.ts
export const mapTiles: Tile[] = [
  {
    name: 'evacuation_zone',
    type: 'vector',
    maxzoom: 13,
  },
  {
    name: 'evacuation_zone_id',
    type: 'vector',
    maxzoom: 10,
  },
  {
    name: 'county_boundary',
    type: 'vector',
  },
  {
    name: 'county_name',
    type: 'vector',
  },
  {
    name: 'fire_perimeters',
    type: 'vector',
  },
  {
    name: 'fire_points',
    type: 'vector',
  },
  {
    name: 'ap_tcp_group',
    type: 'vector',
    maxzoom: 11,
  },
];
