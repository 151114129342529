import { I18n } from '../../../i18n';
import { ZoneBoundary } from '../../Map/types';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  zoneName: string;
  zoneBoundaries: ZoneBoundary | undefined;
}

export const ZoneBoundaries = ({ zoneName, zoneBoundaries }: Props) => {
  if (!zoneBoundaries) {
    return null;
  }

  return (
    <>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {zoneName}
      </Typography>

      <Box sx={{ flexDirection: 'column', display: 'flex', gap: 0.5 }}>
        {['northOf', 'southOf', 'eastOf', 'westOf'].map((bound, index) => (
          <Stack key={index} direction="row" gap={1}>
            <Typography
              variant="body2"
              color="grey.500"
              sx={{ whiteSpace: 'nowrap', minWidth: 70 }}
            >
              {I18n.t(`zoneDetails.zoneBounds.${bound}`)}
            </Typography>
            {
              // eslint-disable-next-line security/detect-object-injection
              <Typography variant="body2">{zoneBoundaries[bound]}</Typography>
            }
          </Stack>
        ))}
      </Box>
    </>
  );
};
