import { Box, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { trackUserGrantedLocationAccess } from 'analytics/fns';

import { ReactComponent as AllowLocation } from '../../assets/icons/allow-location.svg';
import { ModalsContext, ModalsContextIds } from '../../context';
import { I18n } from '../../i18n';
import { Card } from '../../layouts/Card';
import { resetUserLocationPreferences, useAppDispatch } from '../../redux-rtk';
import { LocalStorageKeys, LocationOrigin } from '../../types';
import { PrimaryButton } from '../common/Buttons/PrimaryButton';

const GPSPermissionCard = () => {
  const dispatch = useAppDispatch();
  const contextValue = useContext(ModalsContext);

  const [, setPrefferedOrigin] = useLocalStorage<LocationOrigin>(
    LocalStorageKeys.PreferredOrigin,
    LocationOrigin.Pending,
  );

  const handleAllow = useCallback(() => {
    setPrefferedOrigin(LocationOrigin.Browser);
    dispatch(resetUserLocationPreferences());
    contextValue.setCurrentOpenedModal(ModalsContextIds.GpsPermission);
    trackUserGrantedLocationAccess();
  }, []);

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <AllowLocation style={{ width: 50, height: 50 }} role="img" aria-hidden="true" />
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {I18n.t('search.currentLocation.allowLocation')}
        </Typography>
      </Box>

      <PrimaryButton
        onClick={handleAllow}
        sx={{ width: '100%', mt: 2 }}
        label={I18n.t('button.allow')}
      />
    </Card>
  );
};

export default GPSPermissionCard;
