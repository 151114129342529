const RobotoFont = ["Roboto", "sans-serif"].join(",");
const MontserratFont = ["Montserrat", "sans-serif"].join(",");

// Reference: https://mui.com/material-ui/customization/default-theme/
// line-height should be same height as font-size for h[1-3] tags
// Let layout be responsible for typography margins and paddings
export const TypographyTheme = {
  typography: {
    h1: {
      fontFamily: MontserratFont,
      fontSize: "28px",
      lineHeight: "28px",
      fontWeight: 700,
      color: "#19213e",
      component: "h1",
    },
    h2: {
      fontFamily: MontserratFont,
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "20px",
      textTransform: "uppercase",
      color: "#19213e",
      component: "h2",
    },
    h3: {
      fontFamily: MontserratFont,
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 700,
      textTransform: "uppercase",
      color: "#19213e",
      component: "h3",
    },
    h4: {
      fontFamily: MontserratFont,
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: 400,
      textTransform: "uppercase",
      color: "#19213e",
      component: "h3",
    },
    button: {
      fontSize: "16px",
      fontFamily: RobotoFont,
      textTransform: "initial",
      fontWeight: 500,
      color: "#19213e",
    },
    body1: {
      fontSize: "16px",
      lineHeight: 1.2,
      fontFamily: RobotoFont,
      color: "#19213e",
    },
    body2: {
      fontSize: "14px",
      lineHeight: 1.2,
      fontFamily: RobotoFont,
      color: "#19213e",
    },
    caption: {
      fontSize: "12px",
      lineHeight: 1.2,
      fontFamily: RobotoFont,
      color: "#19213e",
      opacity: 0.8,
      letterSpacing: "0px",
    },
    
  },
};
