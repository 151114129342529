import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useMatch, useNavigate } from 'react-router-dom';

import { I18n } from '../../../../i18n';
import { useFetchHazardDetailsQuery } from '../../../../redux-rtk';
import { PropsWithSx } from '../../../../utils';
import { TextButton } from '../../../common/Buttons';
import { FireIcon } from '../../../Icon/FireIcon';
import { StateOrCurrentLocationChip } from '../../../StateOrCurrentLocationChip';
import { Hazard } from '../../types';
import { BaseMapCard } from '../BaseMapCard';

interface HazardcardProps {
  hazard?: Hazard;
  hazardId?: string;
  onClose?: () => void;
  hasFireLabel?: boolean;
  // userCoordinates?: Position;
  isCloseButtonVisible?: boolean;
}

export const HazardCard = ({
  sx,
  hazard,
  onClose,
  hazardId,
  // userCoordinates,
  hasFireLabel = false,
  isCloseButtonVisible,
}: PropsWithSx<HazardcardProps>) => {
  const navigate = useNavigate();
  const isHazardDetailsPage = useMatch('/hazards/:id');

  const { data, isFetching } = useFetchHazardDetailsQuery(hazardId, {
    skip: !hazardId,
  });

  const _hazard = hazard ?? data;

  const [, stateAbbreviation] = _hazard ? _hazard.state.split('-') : '';

  // TODO: Implement distance from fire when accuracy is high
  // const distanceFromFire = useMemo(() => {
  //   if (!userCoordinates || !_hazard) {
  //     return null;
  //   }

  //   const fromUserCoords = turf.point([...userCoordinates].reverse());
  //   const toFireCoords = turf.point([_hazard.longitude, _hazard.latitude]);

  //   const distance = turf.distance(fromUserCoords, toFireCoords, {
  //     units: "miles",
  //   });

  //   return distance;
  // }, [_hazard, userCoordinates]);

  return (
    <BaseMapCard
      title={hasFireLabel ? 'Fire' : ''}
      Icon={<FireIcon htmlColor="white" role="img" aria-hidden="true"/>}
      iconTitle={_hazard?.name}
      iconSubtitle={`${I18n.t('fires.lastUpdatedDate')}: ${dayjs(
        _hazard?.updatedDate,
      ).format('MM/DD/YY')}`}
      iconBackgroundColor="danger.main"
      onClose={onClose}
      isFetching={isFetching}
      sx={{ minWidth: 300, ...sx }}
      closeButtonLabel={`Close Fire ${_hazard?.name}`}
      isCloseButtonVisible={isCloseButtonVisible}
    >
      {_hazard && (
        <>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <StateOrCurrentLocationChip stateAbbreviation={stateAbbreviation} />

            {!isHazardDetailsPage && (
              <TextButton
                onClick={() => navigate(`/hazards/${_hazard?.id}`)}
                sx={{ position: 'relative', right: -10 }}
                aria-label={`More details for ${_hazard?.name}`}
              />
            )}
          </Box>
        </>
      )}
    </BaseMapCard>
  );
};
