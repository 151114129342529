import { configureStore } from "@reduxjs/toolkit";

import { BaseMapApi, BaseZonehavenApi } from "./api";
import { mapSlice, userSlice } from "./slices";
import { appSlice } from "./slices/appSlice";

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [mapSlice.name]: mapSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [BaseMapApi.reducerPath]: BaseMapApi.reducer,
    [BaseZonehavenApi.reducerPath]: BaseZonehavenApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      BaseMapApi.middleware,
      BaseZonehavenApi.middleware
    );
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
